import React from 'react';

import { useStaticQuery, graphql, navigate } from 'gatsby';

import BlogSection from '../../BlogSection';

export default function GatsbyBlogSection(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "prefooter-background-high" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <BlogSection
            {...props}
            background={{ file: data.bg }}
            navigate={navigate}
        />
    );
}
