import React from 'react';
import Marquee from 'react-fast-marquee';

import ImageComponent from '../ImageComponent';

import style from './style.module.scss';

const CarouselLogos = ({ images = [], options = {}, classes = {} }) => {
    return (
        <Marquee className={style.slider} {...options}>
            {images.map((image, index) => {
                return (
                    <div
                        key={`logo-${index}`}
                        className={[classes?.item].filter(Boolean).join(' ')}>
                        <ImageComponent
                            fadeIn={false}
                            image={image}
                            className={[classes?.image]
                                .filter(Boolean)
                                .join(' ')}
                            alt={image?.alt}
                        />
                    </div>
                );
            })}
        </Marquee>
    );
};

export default CarouselLogos;
