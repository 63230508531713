import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import BenefitComponent from '../BenefitComponent';

import style from './style.module.scss';
function BenefitsSection({
    title,
    subtitle,
    description,
    titleTag,
    benefits,
    dark,
    isShadow,
    ...props
}) {
    const colsByItems = useMemo(() => {
        let col = {
            xs: 12,
            lg: 6,
            xl: 4,
        };

        if (benefits?.length % 4 === 0) {
            col = {
                xs: 12,
                lg: 6,
                xl: 3,
            };
        }

        return col;
    }, [benefits]);

    return (
        <Section
            data-light
            className={`${
                props.previousSectionType === 'DatoCmsInfoCardsSection'
                    ? style.benefitsSection
                    : style.section
            } ${dark ? 'bg-primary-1000' : 'bg-primary-200'}`}>
            <Container>
                <Row>
                    {title && (
                        <Col
                            col={{
                                xs: 12,
                                lg:
                                    props.previousSectionType ===
                                    'DatoCmsInfoCardsSection'
                                        ? 10
                                        : 6,
                            }}
                            offset={{
                                lg:
                                    props.previousSectionType ===
                                    'DatoCmsInfoCardsSection'
                                        ? 0
                                        : dark
                                        ? 0
                                        : 3,
                            }}
                            mb={{
                                xs: dark ? 3 : 5,
                                lg: dark ? 3 : 7,
                                xxl: dark ? 3 : 8,
                            }}>
                            <Heading
                                tag={titleTag}
                                className={`display3 ${
                                    dark
                                        ? 'text-primary-100'
                                        : 'text-primary-900'
                                } ${
                                    props.previousSectionType ===
                                    'DatoCmsInfoCardsSection'
                                        ? ''
                                        : 'text-center'
                                }`}
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-0`}>
                                {title}
                            </Heading>
                        </Col>
                    )}
                    {subtitle && (
                        <Col
                            col={{ xs: 12, lg: 10 }}
                            offset={{ lg: 1 }}
                            mb={{ xs: 5 }}>
                            <Heading
                                tag="h2"
                                className="large text-primary-900"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-200`}>
                                {subtitle}
                            </Heading>
                        </Col>
                    )}
                </Row>
                {description && (
                    <Row>
                        <Col col={{ xs: 12, lg: 6 }} mb={{ xs: 5 }}>
                            <Heading
                                tag="div"
                                className="normal text-primary-400"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-200`}>
                                {description}
                            </Heading>
                        </Col>
                    </Row>
                )}
                <Row className="justify-content-center">
                    {benefits.map((benefit, index) => {
                        return (
                            <Col
                                key={index}
                                col={colsByItems}
                                mb={{ xs: 3, lg: 5 }}>
                                <BenefitComponent
                                    {...benefit}
                                    dark={dark}
                                    isShadow={isShadow}
                                    delay={index * 200}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Section>
    );
}

BenefitsSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    benefits: PropTypes.arrayOf(PropTypes.shape(BenefitComponent.propTypes)),
    dark: PropTypes.bool,
};

export default BenefitsSection;
