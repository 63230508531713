import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';
function FeatureIconComponent({ title, icon, delay, light }) {
    return (
        <div>
            <ImageComponent
                image={icon}
                className={`mb-2 mb-lg-3 ${style.icon}`}
                data-sal="slide-up"
                data-sal-delay={`xs-none--lg-${delay}`}
            />
            <Text
                tag="p"
                className={`${light ? 'text-primary-600' : 'text-primary-100'} normal`}
                data-sal="slide-up"
                data-sal-delay={`xs-none--lg-${delay + 200}`}>
                {title}
            </Text>
        </div>
    );
}

FeatureIconComponent.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.any,
};

export default FeatureIconComponent;
