import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
} from '@solublestudio/react-bootstrap-components';
import TitleGradient from '../TitleGradient';
import ProductItem from '../ProductItem';
function ProductSection({ titleGradient, products }) {
    return (
        <Section data-light className="bg-primary-200">
            <Container>
                <Row>
                    <Col
                        data-sal="slide-up"
                        data-sal-delay="xs-none--lg-0"
                        col={{ xs: 12, lg: 8, xxl: 6 }}
                        offset={{ lg: 2, xxl: 3 }}
                        mb={{ xs: 5, lg: 8, xxl: 15 }}
                        className="text-center">
                        <TitleGradient {...titleGradient} />
                    </Col>
                </Row>
                {products.map((product, i) => {
                    return (
                        <ProductItem
                            key={`product-item-${i}`}
                            {...product}
                            delay={i * 200}
                        />
                    );
                })}
            </Container>
        </Section>
    );
}

ProductSection.propTypes = {
    titleGradient: PropTypes.shape(TitleGradient.propTypes),
    products: PropTypes.arrayOf(PropTypes.shape(ProductItem.propTypes)),
};

export default ProductSection;
