import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import { Navbar, useIsMobile } from '@solublestudio/react-bootstrap-components';
import Link from 'Link';
import PropTypes from 'prop-types';

import HeaderDropdown from '../HeaderDropdown';
import { ReactComponent as Hexagon } from '../../img/Hexagon.svg';

import style from './style.module.scss';

function Header({ logo, logoWhite, rightItems, leftItems, ...props }) {
    const isMobile = useIsMobile();
    const wrapperRef = useRef();
    const [menuStyle, setMenuStyle] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [isInScroll, setIsInScroll] = useState(false);
    const [headerScrollLightBg, setHeaderScrollLightBg] = useState(!props.dark);

    const onDropdownOpen = useCallback(() => {
        if (!wrapperRef?.current || isMobile) {
            return;
        }

        setTimeout(() => {
            let expandedLi = wrapperRef.current.querySelector(
                '[data-dropdown-shown="true"]',
            );
            if (expandedLi) {
                let expandedBg = expandedLi.querySelector('[data-bg]');
                if (expandedBg) {
                    const position = expandedBg.getBoundingClientRect();
                    setMenuStyle({
                        left: `${position.x}px`,
                        width: `${position.width}px`,
                        height: `${position.y + position.height}px`,
                        opacity: 1,
                    });
                }
            }
        }, 0);
    }, [wrapperRef, setMenuStyle, setShowMenu, isMobile]);

    const onDropdownClose = useCallback(() => {
        if (isMobile) {
            return;
        }

        setShowMenu(false);
    }, [setMenuStyle, isMobile]);

    useEffect(() => {
        if (menuStyle) {
            setShowMenu(true);
        }
    }, [menuStyle, setShowMenu]);

    useEffect(() => {
        if (!showMenu) {
            setMenuStyle(null);
        }
    }, [showMenu]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let lightSections = window.document.querySelectorAll('[data-light]');
        let lightSectionPoints = [];

        const onScroll = () => {
            const currentScroll = window.document.documentElement.scrollTop;
            let headerScrollLight = false;
            lightSectionPoints.forEach(([point1, point2]) => {
                if (point1 < currentScroll && point2 > currentScroll) {
                    headerScrollLight = true;
                }
            });

            setHeaderScrollLightBg(headerScrollLight);
        };

        const onResize = () => {
            const currentScroll = window.document.documentElement.scrollTop;
            lightSectionPoints = [];

            [...lightSections].forEach((section) => {
                let sizes = section.getBoundingClientRect();
                lightSectionPoints.push([
                    currentScroll + sizes.y - 60,
                    currentScroll + sizes.y + sizes.height - 60,
                ]);
            });

            onScroll();
        };

        onResize();
        setTimeout(onResize, 2000);

        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
        };
    }, [setHeaderScrollLightBg]);

    const isFirefox = useMemo(
        () =>
            typeof window !== 'undefined' &&
            window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
        [],
    );

    const navbarDark = useMemo(() => {
        if (isFirefox && isInScroll) {
            return false;
        }

        return !headerScrollLightBg;
    }, [headerScrollLightBg, isFirefox, isInScroll]);

    return (
        <header ref={wrapperRef}>
            <Navbar
                isFixed={true}
                logo={logoWhite}
                logoNavbarShown={logo}
                {...props}
                darkForce={navbarDark}
                lightForce={!navbarDark}
                extraClass={`${style.navbar} ${
                    isFirefox ? style.navbarNoBlur : ''
                } ${navbarDark ? style.navbarDark : ''}`}
                wrapperClassName={(isScrolled, navbarShown, navbarAnimating) =>
                    `${navbarShown ? style.navbarShown : ''} ${
                        navbarAnimating ? style.navbarAnimating : ''
                    } ${isScrolled ? `${style.navbarScroll}` : ''} ${
                        !navbarDark ? style.navbarScrollLight : ''
                    }`
                }
                togglerClassName={style.toggler}
                dropdownCollapseClassName={(show) =>
                    `${style.dropdownWrapper} ${
                        show
                            ? style.dropdownWrapperShown
                            : style.dropdownWrapperHidden
                    }`
                }
                extraClassName={style.navbarExtra}
                dropdownClassName={style.dropdown}
                onDropdownOpen={onDropdownOpen}
                onDropdownClose={onDropdownClose}
                onToggleScroll={setIsInScroll}
                navbarExtraContent={
                    <div
                        className={`${style.menuBg} ${
                            showMenu ? style.menuBgShow : ''
                        }`}
                        style={menuStyle || {}}
                    />
                }
                navs={[
                    leftItems
                        ? {
                              align: 'center',
                              items: leftItems.map((li, i) => ({
                                  ...li,
                                  title: (
                                      <>
                                          <Hexagon />
                                          <span>{li.title}</span>
                                      </>
                                  ),
                                  Tag: Link,
                                  type: li.subitems?.length ? 'list' : null,
                                  linkClassName: style.mainNavbarLink,
                                  activeClassName: style.mainNavbarActiveLink,
                                  extraClassName: style.navItemLink,
                                  partiallyActive: true,
                                  scheme: 'gatsby',
                                  dropdown: li.subitems?.length ? (
                                      <HeaderDropdown
                                          position={i}
                                          items={li.subitems}
                                      />
                                  ) : null,
                              })),
                              className: style.mainNavbar,
                          }
                        : null,
                    rightItems
                        ? {
                              align: 'right',
                              items: rightItems.map((ri) => ({
                                  ...ri,
                                  Tag: Link,
                                  extraClassName: style.navItemBtn,
                                  type:
                                      isMobile || !navbarDark
                                          ? 'navbarDarkButton'
                                          : 'navbarButton',
                              })),
                              className: style.secondNavbar,
                          }
                        : null,
                ]}
            />
        </header>
    );
}

export default Header;

const NavItemPropTypes = PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    scheme: PropTypes.string,
    subitems: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        }),
    ),
});

Header.propTypes = {
    logo: PropTypes.string,
    logoWhite: PropTypes.string,
    leftItems: PropTypes.arrayOf(NavItemPropTypes),
    rightItems: PropTypes.arrayOf(NavItemPropTypes),
    dark: PropTypes.bool,
};
