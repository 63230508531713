import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import InfoCardComponent from '../InfoCardComponent';

import style from './style.module.scss';

function InfoCardsSection({ infoCards, downloadLabel }) {
    return (
        <Section data-light className={`bg-primary-200 ${style.section}`}>
            <Container>
                <Row>
                    {infoCards.map((infoCard, index) => {
                        return (
                            <Col
                                key={index}
                                col={{ xs: 12, lg: 3 }}
                                mb={{ xs: 3, lg: 5 }}>
                                <InfoCardComponent
                                    {...infoCard}
                                    downloadLabel={downloadLabel}
                                    delay={index * 200}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Section>
    );
}

InfoCardsSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    InfoCards: PropTypes.arrayOf(PropTypes.shape(InfoCardComponent.propTypes)),
};

export default InfoCardsSection;
