import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Col,
    Row,
    Card,
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';

import Link, { navigate } from 'Link';

import style from './style.module.scss';

function ResourceCard({
    pretitle,
    title,
    titleTag = 'h2',
    slug,
    description,
    cta,
    ctaLabel,
    image,
    bottomText,
    onClick,
    disabled,
    isCarousel,
    horizontal = { xs: false, lg: false },
    detached = false,
    externalLink = null,
    downloadLink = null,
    cardImageStyle = 'cover',
    cardImage,
    cardImageBackup,
    kind,
    isHighlighted = false,
}) {
    const onClickCard = useCallback(() => {
        if (disabled) {
            return null;
        }

        if (onClick) {
            onClick;
        } else if (slug || cta?.link?.slug) {
            navigate(`/${slug || cta?.link?.slug}/`);
        } else if (externalLink || cta?.externalLink) {
            window.open(externalLink || cta.externalLink);
        } else if (downloadLink?.file?.publicURL) {
            const btn = window.document.querySelector(
                `[href="${downloadLink.file.publicURL}"`,
            );

            if (btn) {
                btn.click();
            }
        }
    }, [disabled, onClick, slug, externalLink, cta, downloadLink]);

    const picture = useMemo(
        () => cardImage ?? cardImageBackup ?? image,
        [cardImage, image],
    );

    return (
        <Card
            onClick={onClickCard}
            wrapperClassName={disabled ? style.disabledCard : style.card}
            bodyClassName={style.bodyCard}>
            <Row
                className={clsx([
                    style.cardRow,
                    horizontal['xs'] ? style.horizontal : style.vertical,
                    horizontal['lg']
                        ? style['horizontal--lg']
                        : style['vertical--lg'],
                    isHighlighted && style.highlighted,
                    detached && style.detached,
                ])}>
                <Col
                    col={{
                        xs: horizontal['xs'] ? 5 : 12,
                        md: horizontal['lg'] ? 3 : 12,
                        lg: horizontal['lg'] ? (isHighlighted ? 3 : 4) : 12,
                    }}
                    className={clsx([style.imageContainer])}>
                    <div className={clsx(style.imageWrapper, style?.[kind])}>
                        <ImageComponent
                            image={picture}
                            className={clsx([
                                style.image,
                                style[cardImageStyle],
                            ])}
                        />
                    </div>
                </Col>
                <Col
                    col={{
                        xs: horizontal['xs'] ? 7 : 12,
                        md: horizontal['lg'] ? 9 : 12,
                        lg: horizontal['lg'] ? (isHighlighted ? 9 : 8) : 12,
                    }}
                    className={clsx(
                        style.textContainer,
                        isCarousel && style.xxlPadding,
                    )}>
                    {pretitle ? (
                        <div {...className('mb-2')}>
                            {typeof pretitle === 'object' ? (
                                <>{pretitle}</>
                            ) : (
                                <Heading
                                    tag="span"
                                    className="extraSmall text-primary-600 text-uppercase">
                                    {pretitle}
                                </Heading>
                            )}
                        </div>
                    ) : null}
                    {title &&
                        (slug ? (
                            <Link href={slug} title={title}>
                                <Heading
                                    tag={titleTag}
                                    className="large text-primary-900 mb-2">
                                    {title}
                                </Heading>
                            </Link>
                        ) : (
                            <Heading
                                tag={titleTag}
                                className="large text-primary-900 mb-2">
                                {title}
                            </Heading>
                        ))}
                    {description && (
                        <Text
                            tag="div"
                            className="normal text-primary-600 mb-2">
                            {description}
                        </Text>
                    )}

                    <div className={style.elementBottom}>
                        {disabled
                            ? null
                            : (cta ||
                                  (ctaLabel &&
                                      (slug ||
                                          externalLink ||
                                          downloadLink))) && (
                                  <ButtonComponent
                                      button={{
                                          onClick: (e) => {
                                              if (
                                                  !downloadLink ||
                                                  !downloadLink.file
                                              ) {
                                                  e.preventDefault();
                                              }
                                          },
                                          ...(cta
                                              ? cta
                                              : {
                                                    ...(slug &&
                                                        !externalLink && {
                                                            link: {
                                                                slug:
                                                                    slug ?? '',
                                                            },
                                                        }),
                                                    ...(externalLink && {
                                                        externalLink,
                                                    }),
                                                    ...(downloadLink && {
                                                        href: `/${downloadLink.file.publicURL}`,
                                                        extraAttributes: {
                                                            download:
                                                                downloadLink.basename,
                                                        },
                                                    }),
                                                    label: ctaLabel,
                                                }),
                                          type: 'secondaryButton',
                                      }}
                                  />
                              )}
                    </div>

                    {bottomText && (
                        <Text
                            tag="span"
                            className={clsx([
                                'extraSmall',
                                'text-primary-600',
                                'text-uppercase',
                                'mt-3',
                                style.elementBottom,
                            ])}>
                            {bottomText}
                        </Text>
                    )}
                </Col>
            </Row>
        </Card>
    );
}

ResourceCard.propTypes = {
    pretitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    title: PropTypes.string,
    titleHref: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    cta: PropTypes.object,
    ctaLabel: PropTypes.string,
    image: PropTypes.any,
    bottomText: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isCarousel: PropTypes.bool,
    kind: PropTypes.oneOf(['logo', 'event', 'blog', 'whitepaper', 'webinar']),
    detached: PropTypes.bool,
};

export default ResourceCard;
