import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';

import Tabs from '../Tabs';
import FeaturesIconsSection from '../FeaturesIconsSection';
import FeatureIconComponent from '../FeatureIconComponent';

import style from './style.module.scss';

function FeatureCategorySection({
    title,
    titleTag,
    featuresCategory,
    multipleFeaturesCategory,
}) {
    const [tabActive, setTabActive] = useState(0);

    return (
        <>
            <Section className={`bg-primary-1000 ${style.section}`}>
                <Container>
                    <Row>
                        {title ? (
                            <Col col={{ xs: 12, lg: 6 }} offset={{ lg: 3 }}>
                                <Heading
                                    tag={titleTag}
                                    className="display3 text-primary-100 mb-8 text-center">
                                    {title}
                                </Heading>
                            </Col>
                        ) : null}
                        <Col
                            col={{ xs: 12, lg: 10 }}
                            offset={{ lg: 1 }}
                            mb={{ xs: 8, lg: 7, xxl: 8 }}>
                            {multipleFeaturesCategory.length > 0 ? (
                                <Tabs
                                    links={multipleFeaturesCategory.map(
                                        (feature, i) => ({
                                            ...feature,
                                            active: i === tabActive,
                                            onClick: () => {
                                                setTabActive(i);
                                            },
                                        }),
                                    )}
                                    wrapperClassName={style.mobileTabs}></Tabs>
                            ) : (
                                <Tabs
                                    links={featuresCategory.map(
                                        (feature, i) => ({
                                            ...feature,
                                            active: i === tabActive,
                                            onClick: () => {
                                                setTabActive(i);
                                            },
                                        }),
                                    )}
                                    wrapperClassName={style.mobileTabs}></Tabs>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Section>
            {multipleFeaturesCategory.length > 0
                ? multipleFeaturesCategory.map((f, i) => {
                      return (
                          <div
                              key={i}
                              {...className(
                                  `${tabActive !== i ? 'd-none' : ''}`,
                              )}>
                              <Section
                                  className={`bg-primary-1000 ${style.titleSection}`}>
                                  <Container>
                                      <Row>
                                          <Col
                                              col={{ xs: 12, lg: 6 }}
                                              offset={{ lg: 3 }}>
                                              <Heading
                                                  tag={titleTag}
                                                  className="display3 text-primary-100 mb-8 text-center">
                                                  {f.title}
                                              </Heading>
                                          </Col>
                                      </Row>
                                  </Container>
                              </Section>
                              {f.featuresCategory.map((feature, i) => (
                                  <FeaturesIconsSection
                                      key={i}
                                      {...feature}
                                      className={
                                          style.iconsSection
                                      }></FeaturesIconsSection>
                              ))}
                          </div>
                      );
                  })
                : featuresCategory.map((feature, i) => (
                      <FeaturesIconsSection
                          key={i}
                          {...feature}
                          className={`${tabActive !== i ? 'd-none' : ''} ${
                              style.iconsSection
                          }`}></FeaturesIconsSection>
                  ))}
        </>
    );
}

FeatureCategorySection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    tabs: PropTypes.any,
    featuresCategory: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            features: PropTypes.shape(FeatureIconComponent.propTypes),
        }),
    ),
    multipleFeaturesCategory: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            features: PropTypes.shape(FeatureIconComponent.propTypes),
        }),
    ),
};

export default FeatureCategorySection;
