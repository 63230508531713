import React from 'react';
import PropTypes from 'prop-types';

import CardBG from '../../../CardBG';

import { Heading } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../../../ButtonComponent';

import style from './style.module.scss';
function OtherResourceCard({ title, background, cta }) {
    return (
        <CardBG
            background={background}
            bodyClassName={style.otherResourceCardBody}>
            <Heading
                className={`heading1 ${
                    background ? 'text-primary-100' : 'text-primary-900'
                }`}
                tag="h3">
                {title}
            </Heading>
            {cta && (
                <div>
                    <ButtonComponent
                        button={{
                            ...cta,
                            type: 'secondaryLightButton',
                            className: 'mt-4',
                        }}
                    />
                </div>
            )}
        </CardBG>
    );
}

OtherResourceCard.propTypes = {
    title: PropTypes.string,
    cta: PropTypes.shape(ButtonComponent.propTypes),
};

export default OtherResourceCard;
