import React from 'react';

import { Pagination as RBCPagination } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

import { ReactComponent as ArrowPagination } from '../../img/arrow-pagination.svg';

const Pagination = (props) => {
    return (
        <RBCPagination
            wrapperClassName={style.wrapper}
            pageClassName={style.page}
            disabledClassName={style.disabled}
            arrowClassName={style.arrow}
            activeClassName={style.active}
            arrowSvg={<ArrowPagination />}
            pagesDepth={2}
            {...props}
        />
    );
};

export default Pagination;
