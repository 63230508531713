import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
} from '@solublestudio/react-bootstrap-components';
import HTMLTextComponent from '../HTMLTextComponent';

import style from './style.module.scss';

function HTMLTextSection({ content, order }) {
    return (
        <Section
            data-light
            className={`bg-primary-200 ${
                order === 1 ? style.firstSection : style.section
            }`}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 10, xxl: 8 }}
                        offset={{ lg: 1, xxl: 2 }}>
                        <HTMLTextComponent text={content} />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

HTMLTextSection.propTypes = {
    content: PropTypes.string,
};

export default HTMLTextSection;
