import React from 'react';
import PropTypes from 'prop-types';

import { SocialIcons as RBCSocialIcons } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';
function SocialLinks({
    links = {},
    padding = 0,
    className,
    linkClassName,
    vertical,
    light,
    ...otherProps
}) {
    return (
        <RBCSocialIcons
            size={32}
            padding={padding}
            wrapperClassName={[
                className,
                vertical ? style.wrapperVertical : '',
            ].join(' ')}
            linkClassName={[style.link, light ? style.linkLight : ''].join(' ')}
            icons={Object.keys(links).map((key) => ({
                url: links[key],
                kind: key,
            }))}
            {...otherProps}
        />
    );
}

SocialLinks.propTypes = {
    links: PropTypes.shape({}),
    padding: PropTypes.number,
    light: PropTypes.bool,
};

export default SocialLinks;
