import React from 'react';
import PropTypes from 'prop-types';

import { Heading, className } from '@solublestudio/react-bootstrap-components';
import { ReactComponent as Download } from '../../img/download.svg';

import style from './style.module.scss';

function InfoCardComponent({
    title,
    link,
    downloadLink,
    downloadLabel,
    delay,
}) {
    return (
        <div
            {...className(
                `bg-primary-100 h-100 pt-5 pb-5 pr-3 pl-3 text-center ${style.card}`,
            )}
            data-sal="slide-up"
            data-sal-delay={`xs-none--lg-${delay}`}>
            <Heading tag="h4" className="normal text-primary-900 mb-2">
                {title}
            </Heading>
            <a
                {...className(
                    `smallMono text-primary-600 mb-5 d-block ${style.info}`,
                )}
                href={link.externalLink}
                target="_blank"
                rel="noopener noreferrer">
                {link.label}
            </a>
            {downloadLink && (
                <a
                    {...className(
                        `button d-flex align-items-center justify-content-center ${style.download}`,
                    )}
                    href={downloadLink.file.publicURL}
                    download={downloadLink.basename}>
                    <div {...className('d-flex align-items-center')}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: `${downloadLabel}`,
                            }}
                        />
                        <Download></Download>
                    </div>
                </a>
            )}
        </div>
    );
}

InfoCardComponent.propTypes = {
    title: PropTypes.string,
    link: PropTypes.object,
    downloadLink: PropTypes.object,
};

export default InfoCardComponent;
