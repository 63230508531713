import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Slider,
    className,
} from '@solublestudio/react-bootstrap-components';

import Link from 'Link';

import ResourceCard from '../ResourceCard';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

const sliderSettings = {
    controls: false,
    loop: false,
    nav: true,
    navPosition: 'bottom',
    mouseDrag: true,
    edgePadding: 0,
    gutter: 0,
    items: 1,
};
function CardListSection({
    title,
    titleTag,
    titleCardsTag,
    cta,
    dark,
    xsCardColumns,
    lgCardColumns,
    xxlCardColumns,
    isCarousel,
    cards,
    lgMaxCardNumber,
    sectionClassName,
    thisSectionType,
    previousSectionType,
}) {
    const { titleDark, tightPadding, isEmpty } = useMemo(() => {
        let titleDark = false;
        let tightPadding = false;
        let isEmpty = false;

        if (
            ['NextEventsSection', 'PreviusEventsSection'].indexOf(
                thisSectionType,
            ) !== -1 &&
            previousSectionType === 'DatoCmsTextHeroSection'
        ) {
            titleDark = true;
        }

        if (
            (thisSectionType === 'NextEventsSection' &&
                previousSectionType === 'DatoCmsPreviusEventsSection') ||
            (thisSectionType === 'PreviusEventsSection' &&
                previousSectionType === 'DatoCmsNextEventsSection')
        ) {
            tightPadding = true;
        }

        if (cards.length === 0) {
            isEmpty = true;
        }

        return { titleDark, tightPadding, isEmpty };
    }, [thisSectionType, previousSectionType]);

    return (
        <Section
            data-light="true"
            className={[
                style.section,
                sectionClassName,
                dark && style.dark,
                isCarousel && style.carousel,
                tightPadding && style.tightPadding,
                isEmpty && style.isEmpty,
            ]
                .filter((el) => !!el)
                .join(' ')}>
            <Container>
                <Row className={`mb-5 ${tightPadding ? '' : 'mb-xxl-8'}`}>
                    <Col col={{ xs: 12, lg: 8 }}>
                        {cta ? (
                            <Link href={cta.link.slug}>
                                <Heading
                                    className={`display3 ${
                                        titleDark
                                            ? 'text-primary-100'
                                            : 'text-primary-900'
                                    }`}
                                    tag={titleTag}>
                                    {title}
                                </Heading>
                            </Link>
                        ) : !titleDark ? (
                            <Heading
                                className={`display3 text-primary-900`}
                                tag={titleTag}>
                                {title}
                            </Heading>
                        ) : null}
                    </Col>
                    {cta && (
                        <Col
                            col={{ xs: 12, lg: 4 }}
                            className="d-flex align-items-center justify-content-lg-end mt-2 mt-lg-0">
                            <ButtonComponent
                                button={{
                                    ...cta,
                                    type: 'secondaryButton',
                                    className: '',
                                }}
                            />
                        </Col>
                    )}
                </Row>
                {!isCarousel && (
                    <Row>
                        {cards.map((card, index) => {
                            return (
                                <Col
                                    key={`cardlist-${index}`}
                                    col={{
                                        xs: xsCardColumns,
                                        lg: lgCardColumns,
                                        xxl: xxlCardColumns,
                                    }}
                                    className={`mb-3 mb-lg-5 ${
                                        lgMaxCardNumber &&
                                        index > lgMaxCardNumber - 1
                                            ? 'd-none d-xxl-block'
                                            : ''
                                    }`}>
                                    <ResourceCard
                                        titleTag={titleCardsTag}
                                        {...card}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </Container>
            {isCarousel ? (
                <Slider settings={sliderSettings}>
                    {cards.map((card, index) => {
                        return (
                            <div
                                {...className('pb-2 pb-lg-5 pt-2')}
                                key={`cardlist-carousel-${index}`}>
                                <Container>
                                    <ResourceCard
                                        titleTag={titleCardsTag}
                                        isCarousel={isCarousel}
                                        key={`carousel-${index}`}
                                        {...card}
                                    />
                                </Container>
                            </div>
                        );
                    })}
                </Slider>
            ) : null}
        </Section>
    );
}

CardListSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    titleCardsTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    cta: PropTypes.object,
    dark: PropTypes.bool,
    xsCardColumns: PropTypes.number,
    lgCardColumns: PropTypes.number,
    xxlCardColumns: PropTypes.number,
    isCarousel: PropTypes.bool,
    cards: PropTypes.arrayOf(PropTypes.shape(ResourceCard.propTypes)),
    lgMaxCardNumber: PropTypes.number,
    titleDark: PropTypes.bool,
};

export default CardListSection;
