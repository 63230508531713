import React, { useState, useEffect, useRef, useCallback } from 'react';

import style from './style.module.scss';

let globalId = 0;

if (typeof window !== 'undefined') {
    window.jQuery =
        window.jQuery ||
        function () {
            return {
                serializeArray: () => {},
                dispatchEvent: () => {},
            };
        };
}

export default function HubspotForm({
    loading,
    onReady,
    onSubmit,
    submitDisabled = false,
    submitBlock,
    blockedDomains,
    className,
    subscriptionForm,
    fromHubspot,
    ...props
}) {
    const elementRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [isMounted, setMounted] = useState(false);

    const [id, setId] = useState();

    let touched = useRef({}).current;

    useEffect(() => {
        if (typeof window === 'undefined' || fromHubspot) {
            return;
        }

        globalId++;
        setId(`reactHubspotForm${globalId}`);

        let scr = document.createElement('script'),
            head = document.head || document.getElementsByTagName('head')[0];

        scr.type = 'text/javascript';
        scr.src = '//js.hsforms.net/forms/v2.js';
        scr.async = false;

        head.insertBefore(scr, head.firstChild);

        if (scr) {
            scr.addEventListener('load', function () {
                setMounted(true);
            });
        }

        return () => {
            if (scr) {
                scr.removeEventListener('load', () => {});
            }
        };
    }, [setId, fromHubspot]);

    const setupSubmitDisabled = useCallback(
        (value) => {
            if (!elementRef.current) {
                return;
            }

            let input = elementRef.current.querySelector(
                'input[type="submit"]',
            );
            if (input) {
                input.disabled = value;
            }
        },
        [elementRef],
    );

    const onFormReady = useCallback(() => {
        if (!elementRef.current || !subscriptionForm) {
            return;
        }

        const inputs = elementRef.current.querySelectorAll(
            'input[type="text"],input[type="email"],input[type="tel"],input[type="number"],input[type="date"],textarea',
        );

        const onFocus = (e) => {
            if (e.target.dataset.error) {
                delete e.target.dataset.error;
                const label = e.target.parentNode.parentNode.querySelector(
                    '[data-error-message]',
                );
                if (label) {
                    label.remove();
                }
                setupSubmitDisabled(false);
            }
        };

        const onBlur = (e) => {
            if (touched[e.target.name]) {
                const disabled = [...inputs].some((input) =>
                    input.className.includes('hubspot-field-with-error'),
                );

                setupSubmitDisabled(disabled);
                return;
            }

            touched = {
                ...touched,
                [e.target.name]: true,
            };
        };

        [...inputs].forEach((input) => {
            input.addEventListener('focus', onFocus);
            input.addEventListener('blur', onBlur);
        });

        if (elementRef?.current?.childNodes[0]) {
            elementRef?.current?.childNodes[0].addEventListener(
                'submit',
                (ev) => {
                    const disabled = [...inputs].some((input) =>
                        input.className.includes('hubspot-field-with-error'),
                    );
                    setupSubmitDisabled(disabled);
                },
            );
        }

        setupSubmitDisabled(submitDisabled);
    }, [elementRef, submitDisabled, touched, subscriptionForm]);

    const onAnyFormReady = useCallback(
        (readyTimeout = 0) => {
            setLoaded(true);
            if (onReady) {
                onReady(elementRef.current);
            }

            setTimeout(onFormReady, readyTimeout);
        },
        [elementRef, setLoaded, onReady, onFormReady],
    );

    useEffect(() => {
        if (loaded) {
            return;
        }

        if (fromHubspot) {
            setTimeout(onAnyFormReady, 500);
            return;
        }

        if (id && isMounted && window.hbspt && elementRef.current) {
            let options = {
                ...props,
                submitButtonClass: style.submit,
                errorClass: `${style['with-error']} hubspot-field-with-error`,
                errorMessageClass: style['error-message'],
                target: `#${id}`,
                blockedDomains: blockedDomains ? blockedDomains : [],
                onFormSubmit: ($form) => {
                    let formData = $form.serializeArray();
                    if (onSubmit) {
                        onSubmit(formData);
                    }
                },
                onFormReady: onAnyFormReady,
            };

            window.hbspt.forms.create(options);
        }
    }, [
        id,
        elementRef,
        props,
        onSubmit,
        onAnyFormReady,
        blockedDomains,
        isMounted,
        fromHubspot,
    ]);

    useEffect(() => {
        setupSubmitDisabled(submitDisabled);
    }, [submitDisabled]);

    return id || fromHubspot ? (
        <>
            <div
                data-hubspot-form
                className={[
                    style.form,
                    submitDisabled ? style['form--submit-disabled'] : '',
                    submitBlock ? style['form--submit-block'] : '',
                    subscriptionForm ? style['form--subscription'] : '',
                    className ? className : '',
                ].join(' ')}
                ref={elementRef}
                {...(id && { id })}
                style={{ display: loaded ? 'block' : 'none' }}
            />
            {!loaded && loading ? loading : null}
        </>
    ) : loading ? (
        <>{loading}</>
    ) : null;
}
