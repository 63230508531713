import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

function TitleSection({ title, subtitle }) {
    return (
        <Section data-light className={style.section}>
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 5 }}>
                        <Heading
                            tag="h1"
                            className="display1 text-primary-900 mb-3"
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-0`}>
                            {title}
                        </Heading>
                        {subtitle && (
                            <Text
                                tag="div"
                                className="normal text-primary-600"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-200`}>
                                {subtitle}
                            </Text>
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

TitleSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default TitleSection;
