import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';
function NotFoundPage({ title, titleTag, text, background, cta }) {
    return (
        <Section>
            {background && (
                <ImageComponent className={style.image} image={background} />
            )}
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 8 }}
                        offset={{ lg: 2 }}
                        className="text-center">
                        <Heading
                            tag={titleTag}
                            className="display1 text-primary-100">
                            {title}
                        </Heading>
                        <Text
                            tag="div"
                            className="large text-primary-100 mt-5 mb-8 mb-xxl-10">
                            {text}
                        </Text>
                        <ButtonComponent
                            button={{
                                ...cta,
                                type: 'primaryButton',
                            }}></ButtonComponent>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

NotFoundPage.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    text: PropTypes.string,
    cta: PropTypes.object,
};

export default NotFoundPage;
