import React, { useMemo } from "react"
import { Header } from "@solublestudio/bdeo-design-system"
import { Link } from "@solublestudio/gatsby-theme-soluble-source"
import langs from "./langs.json"

import "@solublestudio/bdeo-design-system/src/scss/fonts.scss"
// CSS added to Cookiehub directly
// import "@solublestudio/bdeo-design-system/src/scss/cookiehub.scss"
import "@solublestudio/bdeo-design-system/src/scss/transitions.scss"

const getSlugFromLanguage = (alternateLanguages, language) => {
  let item = alternateLanguages.find((al) => al.language === language)

  return typeof item !== "undefined" && typeof item.slug !== "undefined"
    ? `/${item.slug}`
    : `/${language}/`
}

export default ({ title, isDark, navs, language, extraParams }) => {
  const [mainNavs, buttons] = useMemo(() => {
    const formatLink = (link, depth) => {
      return {
        title: link.label,
        href: link.slug,
        hover: link.description || null,
        type: link.type === "button" ? "button" : null,
        subitems:
          link?.childLinks?.length > 0 ? link.childLinks.map(formatLink) : [],
      }
    }

    const main = navs.map(formatLink)

    const mainNavs = main.filter((n) => n.type !== "button")

    const buttons = main.filter((n) => n.type === "button")

    return [mainNavs, buttons]
  }, [])

  const leftItems = useMemo(
    () =>
      extraParams?.hideNavs
        ? []
        : [
          ...mainNavs,
          {
            title: language?.toUpperCase(),
            href: extraParams?.url || "#",
            subitems: Object.keys(langs)
              .filter((lang) => lang !== language)
              .map((lang) => ({
                title: langs[lang],
                href: getSlugFromLanguage(
                  extraParams?.alternateLanguages || [],
                  lang
                ),
                onClick: () => {
                  window.localStorage.lang = lang
                },
              })),
          },
        ],
    [navs, language, extraParams, mainNavs]
  )

  const rightItems = useMemo(
    () =>
      buttons.map((n) => ({
        ...n,
        scheme: "button",
      })),
    [buttons]
  )

  return (
    <Header
      leftItems={leftItems}
      rightItems={rightItems}
      dark={isDark}
      logoAlt={title}
      LinkTag={Link}
      href={language === "es" ? "/" : `/${language}/`}
    />
  )
}
