import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';
import TitleGradient from '../TitleGradient';

function FeatureTextComponent({
    titleGradient,
    textClassName,
    description,
    cta,
    dark,
    delay,
}) {
    return (
        <>
            {titleGradient && (
                <TitleGradient
                    {...titleGradient}
                    dark={dark}
                    data-sal="slide-up"
                    data-sal-delay={`xs-none--lg-${delay}`}
                />
            )}

            {description && (
                <Text
                    tag="div"
                    className={[
                        'normal',
                        cta ? 'mt-5' : 'mt-3',
                        !cta && 'mt-xxl-5',
                        dark ? 'text-primary-600' : 'text-primary-400',
                        textClassName ?? '',
                    ].join(' ')}
                    data-sal="slide-up"
                    data-sal-delay={`xs-none--lg-${delay + 200}`}>
                    {description}
                </Text>
            )}

            {cta && (
                <ButtonComponent
                    button={{
                        ...cta,
                        className: 'mt-5 mt-xxl-8',
                        type: dark ? 'primaryDarkButton' : 'primaryButton',
                    }}
                />
            )}
        </>
    );
}

FeatureTextComponent.propTypes = {
    titleGradient: PropTypes.shape(TitleGradient.propTypes),
    textClassName: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape(ButtonComponent.propTypes),
    dark: PropTypes.bool,
};

export default FeatureTextComponent;
