import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { className } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';
import Link from 'Link';
import { ReactComponent as SubmenuArrow } from '../../img/SubmenuArrow.svg';

import hoverStyle from '../ButtonComponent/hover-style.module.scss';
import style from './style.module.scss';

function HeaderDropdown({ items, position }) {
    const hasTwoLevels = useMemo(
        () => !!items.find((item) => item.subitems?.length),
        [items],
    );

    return (
        <>
            <div
                {...className(`${style.bg}`)}
                data-position={position}
                data-items={hasTwoLevels ? items.length : 1}
                data-bg={true}
            />
            {hasTwoLevels ? (
                items.map((item, i) => (
                    <div
                        {...className(style.wrapper)}
                        data-position={position}
                        data-items={items.length}
                        key={`group-${i}`}>
                        <ButtonComponent
                            button={{
                                href: item.href ?? '#',
                                label: item.title,
                                type: 'secondaryButton',
                                className: style.btn,
                                size: 'sm',
                                hover: item.hover,
                            }}
                        />
                        {item.subitems?.length && (
                            <ul
                                className={style.wrapperSubItems}
                                key={`group-subitems-${i}`}>
                                <>
                                    {item.subitems.map((subitem, si) => (
                                        <li>
                                            <Link
                                                key={`subitem-${i}-${si}`}
                                                href={subitem.href ?? '#'}
                                                {...className(
                                                    `extraSmall ${
                                                        style.subitem
                                                    } ${
                                                        subitem.hover
                                                            ? `${
                                                                  hoverStyle.hover
                                                              } ${
                                                                  hoverStyle[
                                                                      'svg-start'
                                                                  ]
                                                              } ${
                                                                  hoverStyle[
                                                                      subitem
                                                                          .hover
                                                                  ]
                                                              }`
                                                            : ''
                                                    }`,
                                                )}>
                                                <SubmenuArrow />
                                                {subitem.title}
                                            </Link>
                                        </li>
                                    ))}
                                </>
                            </ul>
                        )}
                    </div>
                ))
            ) : (
                <div
                    {...className(`${style.wrapper} ${style.listItemsWrapper}`)}
                    data-position={position}
                    data-items={1}>
                    {items.map((item, i) => {
                        if (item?.type === 'button') {
                            return (
                                <ButtonComponent
                                    key={`subitem-${i}`}
                                    button={{
                                        ...item,
                                        label: item.title,
                                        type: 'secondaryButton',
                                        size: 'sm',
                                        block: true,
                                        className: style.btnSubItem,
                                    }}
                                />
                            );
                        }
                        return (
                            <Link
                                key={`subitem-${i}`}
                                href={item.href}
                                onClick={item.onClick || null}
                                {...className(
                                    `extraSmall ${style.subitem} ${
                                        item.hover
                                            ? `${hoverStyle.hover} ${
                                                  hoverStyle['svg-start']
                                              } ${hoverStyle[item.hover]}`
                                            : ''
                                    }`,
                                )}>
                                {item.title}
                            </Link>
                        );
                    })}
                </div>
            )}
        </>
    );
}

HeaderDropdown.defaultProps = {
    LinkTag: 'a',
};

HeaderDropdown.propTypes = {
    LinkTag: PropTypes.any,
    position: PropTypes.number,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        }),
    ),
};

export default HeaderDropdown;
