import React from 'react';
import PropTypes from 'prop-types';

import {
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';
function BenefitComponent({ title, description, delay, cta, isShadow, dark }) {
    return (
        <div
            {...className(
                `${dark ? 'bg-primary-900' : 'bg-primary-100'}
                ${
                    isShadow ? 'shadow' : ''
                } h-100 p-3 pt-lg-5 pb-lg-5 pr-lg-4 pl-lg-4 ${style.benefit}`,
            )}
            data-sal="slide-up"
            data-sal-delay={`xs-none--lg-${delay}`}>
            {title && (
                <Heading
                    tag="h4"
                    className={`${
                        dark ? 'text-primary-100' : 'text-primary-900'
                    } large mb-2 mb-xxl-3`}>
                    {title}
                </Heading>
            )}
            <Text
                tag="div"
                className={`normal ${
                    dark ? 'text-primary-400' : 'text-primary-600'
                }`}
                data-sal="slide-up"
                data-sal-delay={`xs-none--lg-${delay + 200}`}>
                {description}
            </Text>
            {cta && (
                <ButtonComponent
                    button={{
                        ...cta,
                        type: dark ? 'secondaryLightButton' : 'secondaryButton',
                        className: 'mt-3',
                    }}></ButtonComponent>
            )}
        </div>
    );
}

BenefitComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.object,
    shadow: PropTypes.bool,
};

export default BenefitComponent;
