import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';
import TitleGradient from '../TitleGradient';
import ButtonComponent from '../ButtonComponent';
import CarouselLogos from '../CarouselLogos';

import videoAspect from '../../img/169.svg';

import HeroIcon from './heroIcon';

import style from './style.module.scss';

function HeroSection({ titleGradient, description, cta, logos, video }) {
    const videoWrapperRef = useRef();

    useEffect(() => {
        if (typeof window === 'undefined' || !videoWrapperRef?.current) {
            return;
        }

        videoWrapperRef.current.querySelector('video').play();
    }, [videoWrapperRef]);

    const carouselSettings = {
        speed: 100,
        pauseOnHover: true,
        autoFill: true,
    };

    return (
        <Section className={`bg-primary-900 ${style.section}`}>
            {video && (
                <div
                    ref={videoWrapperRef}
                    className={style.videoWrapper}
                    dangerouslySetInnerHTML={{
                        __html: `<img src="${videoAspect}" /><video preload="auto" class="${style.video}" loop muted data-play="true" playsinline>
                    <source
                        src="${video.publicURL}"
                        type="video/${video.extension}"
                    />
                </video>`,
                    }}
                />
            )}
            <div
                {...className(
                    ` pt-10 pt-lg-15 pb-10 pb-lg-15 ${style.heroContent}`,
                )}>
                <Container>
                    <Row>
                        <Col
                            col={{ xs: 12, md: 10, lg: 9, xxl: 8 }}
                            offset={{ xxl: 1 }}
                            data-sal="slide-up"
                            data-sal-delay="xs-none--lg-0">
                            <TitleGradient
                                display="display1"
                                {...titleGradient}
                            />
                        </Col>
                        <Col
                            col={{ xs: 12, md: 8, lg: 6, xxl: 5 }}
                            offset={{ xxl: 1 }}
                            data-sal="slide-up"
                            data-sal-delay="xs-none--lg-200">
                            <Text
                                tag="div"
                                className="normal text-primary-100 mt-5 mb-8">
                                {description}
                            </Text>
                            <ButtonComponent
                                button={{
                                    ...cta,
                                    type: 'primaryButton',
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
                <HeroIcon className={[style.heroImage].join(' ')} />
            </div>

            <Container>
                <div
                    {...className(`mb-4 ${style.carouselLogos}`)}
                    data-sal="slide-up"
                    data-sal-delay="xs-none--lg-400">
                    {logos?.length > 0 ? (
                        <CarouselLogos
                            images={logos}
                            options={carouselSettings}
                            classes={{
                                image: style.carouselLogoItem,
                            }}
                        />
                    ) : null}
                </div>
            </Container>
        </Section>
    );
}

HeroSection.propTypes = {};

export default HeroSection;
