import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Col,
    Container,
    Section,
    Row,
} from '@solublestudio/react-bootstrap-components';

import FeatureTextComponent from '../FeatureTextComponent';

import BGGraph from './components/BGGraph';
import Steps from './components/Steps';
import TitleGradient from '../TitleGradient';

import style from './style.module.scss';

function FeatureSection({
    titleGradient,
    description,
    dark,
    cta,
    hexagonSteps,
}) {
    const stepsSplited = useMemo(
        () => hexagonSteps && hexagonSteps.split(';'),
        [hexagonSteps],
    );

    return (
        <Section
            {...(!dark && { 'data-light': true })}
            className={[
                style.section,
                dark ? 'bg-primary-900' : 'bg-primary-100',
            ].join(' ')}>
            {dark && <BGGraph />}
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 6 }}
                        offset={{ lg: 2, xxl: 1 }}
                        className={[
                            style.featureText,
                            dark ? 'pb-lg-15' : '',
                        ].join(' ')}>
                        <FeatureTextComponent
                            titleGradient={titleGradient}
                            description={description}
                            cta={cta}
                            dark={!dark}
                            delay={0}
                        />
                    </Col>
                </Row>
            </Container>
            <Steps steps={stepsSplited} />
        </Section>
    );
}

FeatureSection.propTypes = {
    titleGradient: PropTypes.shape(TitleGradient.propTypes),
    description: PropTypes.string,
    dark: PropTypes.bool,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
        }),
    ),
};

export default FeatureSection;
