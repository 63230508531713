import React, { useState, useEffect, useRef } from 'react';

import line from './line.png';

import style from './style.module.scss';

export default function BGGraph() {
    const [appear, setAppear] = useState(false);
    const [appearSecondary, setAppearSecondary] = useState(false);
    const ref = useRef();

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        ref?.current?.addEventListener('sal:in', ({ detail }) => {
            if (detail?.target) {
                setAppear(true);

                setTimeout(() => {
                    setAppearSecondary(true);
                }, 950);
            }
        });
    }, []);

    return (
        <div className={style.container} data-sal="" ref={ref}>
            <div
                className={[style.draw, appear && style[`draw--appear`]].join(
                    ' ',
                )}
                style={{ backgroundImage: `url(${line})` }}>
                {Array(4)
                    .fill(1)
                    .map((a, i) => (
                        <div
                            key={`ft-point-${i}`}
                            className={[
                                style.point,
                                style[`point-${i + 1}`],
                                appearSecondary &&
                                    style[`point-${i + 1}--appear`],
                            ].join(' ')}
                        />
                    ))}

                {Array(12)
                    .fill(1)
                    .map((a, i) => (
                        <div
                            key={`ft-line-${i}`}
                            className={[
                                style.line,
                                style[`line-${i + 1}`],
                                appearSecondary &&
                                    style[`line-${i + 1}--appear`],
                            ].join(' ')}
                        />
                    ))}
            </div>
        </div>
    );
}
