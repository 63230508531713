import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function TitleGradient({
    title,
    titleTag,
    colorPattern,
    dark,
    className,
    display,
    ...props
}) {
    return (
        <Heading
            tag={titleTag}
            className={`${display ? display : 'display3'} ${
                className ? className : ''
            } ${dark ? 'text-primary-900' : 'text-primary-100'} ${
                colorPattern
                    ? `${style.titleGradient} ${style[colorPattern]}`
                    : ''
            }`}
            {...props}>
            {title}
        </Heading>
    );
}

TitleGradient.defaultProps = {};

TitleGradient.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.string,
    colorPattern: PropTypes.oneOf(['red', 'green', 'blue', 'purple']),
    className: PropTypes.string,
    dark: PropTypes.bool,
};
