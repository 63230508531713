import React, { forwardRef } from 'react';

import { Tabs as RBCTabs } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Tabs = forwardRef(
    (
        { wrapperClassName, liClassName, linkClassName, links, ...props },
        ref,
    ) => {
        return (
            <RBCTabs
                ref={ref}
                className={`${wrapperClassName ? wrapperClassName : ''} ${
                    style.wrapper
                }`}
                liClassName={`${style.tab} ${liClassName ? liClassName : ''}`}
                linkClassName={`smallMono ${style.link} ${
                    linkClassName ? linkClassName : ''
                }`}
                items={links.map((l) => ({
                    ...l,
                    Tag: 'h4',
                }))}
                {...props}
            />
        );
    },
);

export default Tabs;
