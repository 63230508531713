import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';
function InternalHero({ title, titleTag, description, pictures, background }) {
    const cols = useMemo(() => Array(5, 3, 4), []);

    return (
        <Section className={[style.section, 'bg-primary-1000'].join(' ')}>
            {background && (
                <ImageComponent
                    className={style.backgroundImage}
                    image={background}
                />
            )}
            <Container className="pb-5">
                <Row>
                    <Col
                        col={{ xs: 12, lg: 6 }}
                        offset={{ lg: 3 }}
                        className="text-center">
                        <Heading
                            tag={titleTag}
                            className="display1 text-primary-100"
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-0`}>
                            {title}
                        </Heading>
                        <Text
                            tag="div"
                            className="normal text-primary-100 mt-5 mb-12 mb-lg-10 mb-xxl-15"
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-200`}>
                            {description}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    {cols.map((col, i) => (
                        <Col
                            col={{ xs: col }}
                            key={`internal-hero-image-${i}`}
                            className={
                                i === 1
                                    ? 'mt-2 mb-2 mt-lg-5 mb-lg-5 mt-xxl-7 mb-xxl-7 '
                                    : ''
                            }>
                            <div
                                {...className([style.wrapperImage].join(' '))}
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-${i * 200}`}>
                                {pictures[i] && (
                                    <ImageComponent
                                        image={pictures[i]}
                                        className={style.image}
                                    />
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Section>
    );
}

InternalHero.propTypes = {};

export default InternalHero;
