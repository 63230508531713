import React from 'react';

import TitleGradient from '../../../TitleGradient';

import { className, Text } from '@solublestudio/react-bootstrap-components';

export default function ProcessStep({
    title,
    colorPattern,
    classNameTitle,
    activeClassName,
    inactiveClassName,
    description,
}) {
    return (
        <div {...className('position-relative pt-lg-2')}>
            {title && (
                <div {...className('position-relative')}>
                    <TitleGradient
                        title={title}
                        titleTag="h3"
                        colorPattern={null}
                        dark={true}
                        className={[classNameTitle, inactiveClassName, 'large']
                            .filter((el) => !!el)
                            .join(' ')}
                    />
                    <TitleGradient
                        title={`<b>${title}</b`}
                        titleTag="h3"
                        dark={true}
                        colorPattern={colorPattern ?? 'blue'}
                        className={[classNameTitle, activeClassName, 'large']
                            .filter((el) => !!el)
                            .join(' ')}
                    />
                </div>
            )}
            {description && (
                <Text tag="div" className="normal text-primary-600 pt-6">
                    {description}
                </Text>
            )}
        </div>
    );
}
