import React, { useMemo, useCallback } from "react"
import { Footer, navigate } from "@solublestudio/bdeo-design-system"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"
import { useStaticQuery } from "gatsby"

import langs from "./langs.json"

let formatLink = (item) => ({
  title: item.label,
  href: item.slug,
  target: item.openInNewWindow ? "_blank" : null,
  detached: item.type === "button",
})

export default ({ navs, language, extraParams }) => {
  const _ = useTranslate()

  const {
    allDatoCmsOption: { nodes: optionNodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsOption {
        nodes {
          locale
          footerText
          footerLogos {
            file {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const { footerText, footerLogos } = useMemo(() => {
    let option = {
      footerText: null,
      footerLogos: null,
    }

    if (!optionNodes?.length) return option

    option = optionNodes.find((o) => o.locale === language) ?? option

    return option
  }, [language, optionNodes])

  const links = useMemo(() => {
    let footerLinks = []
    let col = 1

    while (col <= 4) {
      footerLinks.push(
        navs.find((n) => n.label === `_${col}`)?.childLinks?.map(formatLink)
      )

      col++
    }

    return {
      footerLinks,
      legalLinks:
        navs.find((n) => n.label === "_bottom")?.childLinks?.map(formatLink) ||
        [],
      socialLinks:
        navs.find((n) => n.label === "_social")?.childLinks?.map(formatLink) ||
        [],
    }
  }, [navs])

  const onChange = useCallback(
    (newLang) => {
      window.localStorage.lang = newLang
      if (newLang !== language) {
        let item = extraParams?.alternateLanguages.find(
          (al) => al.language === newLang
        )

        typeof item !== "undefined" && typeof item.slug !== "undefined"
          ? navigate(`/${item.slug}`)
          : navigate(`/${newLang}/`)
      }
    },
    [extraParams, language]
  )

  return (
    <Footer
      hideNavs={extraParams?.hideNavs}
      bdeoInfo={_["footer.address"].html}
      copyright={_["footer.copyright"].text}
      {...links}
      footerLogos={footerLogos}
      footerText={footerText}
      languages={{
        current: language,
        options: langs,
        onChange,
      }}
    />
  )
}
