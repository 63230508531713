import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../ImageComponent';
import DescriptionComponent from '../DescriptionComponent';

import style from './style.module.scss';
import CardVideo from '../CardVideo';

function ProductItem({
    title,
    titleTag,
    description,
    ctas,
    image,
    imageLeft,
    delay,
    video,
    videoImage,
}) {
    return (
        <Row className={`${style.productItem} align-items-center`}>
            <Col
                col={{ xs: 12, lg: 5, xxl: 4 }}
                order={{ xs: 2, lg: imageLeft ? 2 : 1 }}
                offset={{ lg: imageLeft ? 1 : 0, xxl: 1 }}>
                <DescriptionComponent
                    title={title}
                    titleTag={titleTag}
                    description={description}
                    ctas={ctas}
                    buttonClassName={style.productItemBtn}
                    delay={delay}
                />
            </Col>
            <Col
                data-sal="slide-up"
                data-sal-delay={`xs-none--lg-${delay}`}
                col={{ xs: 12, lg: 6, xxl: 5 }}
                order={{ xs: 1, lg: imageLeft ? 1 : 2 }}
                offset={{ lg: imageLeft ? 0 : 1, xxl: 1 }}
                mb={{ xs: 4, lg: 0 }}>
                {video ? (
                    <CardVideo
                        video={video}
                        background={videoImage}></CardVideo>
                ) : image ? (
                    <ImageComponent
                        className={style.productImage}
                        image={image}
                    />
                ) : null}
            </Col>
        </Row>
    );
}

ProductItem.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    ctas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            slug: PropTypes.string,
        }),
    ),
    image: PropTypes.object,
    imageLeft: PropTypes.bool,
};

export default ProductItem;
