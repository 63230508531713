import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Col,
    Container,
    Section,
    Row,
    Heading,
    Text,
    getWindowScroll,
} from '@solublestudio/react-bootstrap-components';

import StepCard from './components/StepCard';
import Bar from './components/Bar';

function StepSection({ steps, title, description }) {
    const refBar = useRef();
    const stepsRef = useRef();

    useLayoutEffect(() => {
        if (
            window === undefined ||
            !refBar?.current.progressContainerRef ||
            !refBar?.current.progressRef ||
            !refBar?.current.parentRef ||
            !stepsRef?.current
        ) {
            return;
        }

        const progressContainerRef = refBar?.current?.progressContainerRef;
        const progressRef = refBar?.current?.progressRef;
        const parentRef = refBar?.current?.parentRef;

        const dataPoints = stepsRef.current.querySelectorAll(
            '[data-point-active="false"]',
        );

        let index = 0;
        let parentFirstY = Infinity;
        let parentLastY = Infinity;
        let height = 0;
        let percentage = 0;

        const takePosition = () => {
            if (!progressContainerRef.current || !parentRef.current) {
                return;
            }

            if (dataPoints.length > 0) {
                const currentTopScroll = document.documentElement.scrollTop;

                const parentRect = parentRef?.current.getBoundingClientRect();

                const lastPositionBottom = currentTopScroll + parentRect.bottom;

                parentFirstY = currentTopScroll + parentRect.y;

                height = lastPositionBottom - parentFirstY;

                parentLastY = parentFirstY + height;

                progressContainerRef.current.style.height = `100%`;
                progressContainerRef.current.style.visibility = 'visible';
            }
        };

        setTimeout(takePosition, 250);

        window.addEventListener('resize', takePosition);

        const onScroll = (e) => {
            const currentTopScroll = getWindowScroll().y;
            const viewportPosition = window.innerHeight / 2;
            const scrollPosition = currentTopScroll + viewportPosition;

            const progressRefCompleted = progressRef.current.getAttribute(
                'data-progress-completed',
            );

            const currentLinePosition = scrollPosition - parentFirstY;

            if (
                scrollPosition > parentFirstY &&
                scrollPosition < parentLastY &&
                progressRefCompleted !== 'true'
            ) {
                if (index <= dataPoints.length - 1) {
                    const currentPoint = dataPoints[index];
                    let currentPointRect = currentPoint.getBoundingClientRect();

                    if (
                        scrollPosition >=
                        currentTopScroll +
                            currentPointRect.y -
                            currentPointRect.height / 3
                    ) {
                        currentPoint.setAttribute('data-point-active', true);
                        index++;
                    }
                }
                percentage = (100 * currentLinePosition) / height;
            } else if (scrollPosition >= parentLastY) {
                const dataPointsInactive = stepsRef.current.querySelectorAll(
                    '[data-point-active="false"]',
                );

                if (dataPointsInactive.length > 0) {
                    [...dataPointsInactive].forEach((point) => {
                        point.setAttribute('data-point-active', true);
                    });
                }

                percentage = 100;

                progressRef.current.setAttribute(
                    'data-progress-completed',
                    true,
                );
            }

            if (progressRef.current) {
                progressContainerRef.current.style = `--height:${percentage}%`;
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('resize', takePosition);

            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <Section className="bg-primary-1000 position-relative">
            <Bar ref={refBar} />
            <Container>
                <Row>
                    {(title || description) && (
                        <Col
                            col={{ xs: 12, lg: 6 }}
                            offset={{ lg: 3 }}
                            className="mb-8 mb-lg-10">
                            <Heading
                                className="display3 text-primary-100 text-center "
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-0`}>
                                {title}
                            </Heading>
                            <Text
                                tag="div"
                                className="normal text-primary-400 text-center mt-3 mt-lg-5"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-200`}>
                                {description}
                            </Text>
                        </Col>
                    )}
                </Row>
                <Row ref={stepsRef}>
                    {steps?.length > 0 &&
                        steps.map((step, i) => (
                            <Col
                                key={`step-section-item-${i}`}
                                col={{ xs: 12, lg: 10 }}
                                offset={{ lg: 1 }}
                                className="mb-3 mb-lg-5">
                                <StepCard
                                    {...step}
                                    key={`step-card-${i}`}
                                    index={`${i + 1}`}
                                />
                            </Col>
                        ))}
                </Row>
            </Container>
        </Section>
    );
}

StepSection.propTypes = {
    steps: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default StepSection;
