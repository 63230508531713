import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    className as classNameFnc,
} from '@solublestudio/react-bootstrap-components';
import Link from 'Link';

import style from './style.module.scss';
import hoverStyle from './hover-style.module.scss';

import { ReactComponent as SmallArrowLeft } from '../../img/SmallArrowLeft.svg';
import { ReactComponent as SmallArrow } from '../../img/SmallArrow.svg';
import { ReactComponent as Arrow } from '../../img/Arrow.svg';

const ButtonComponent = forwardRef(
    ({ button = null, buttons = [], Tag = Link, href = null }, ref) => {
        let theButtons = button ? [button] : buttons;

        return (
            <>
                {theButtons.map(
                    (
                        {
                            className,
                            block,
                            link,
                            isSubmit,
                            externalLink,
                            target,
                            label,
                            loading,
                            disabled,
                            arrow,
                            size,
                            type,
                            hover,
                            color,
                            downloadLink,
                            ...props
                        },
                        i,
                    ) => (
                        <Button
                            key={i}
                            ref={ref}
                            block={block ? true : false}
                            type={type}
                            className={`${style[type]} ${className} ${
                                hover
                                    ? `${hoverStyle.hover} ${hoverStyle[hover]}`
                                    : ''
                            } ${
                                color
                                    ? `${hoverStyle.color} ${
                                          hoverStyle[color]
                                      } ${
                                          type === 'tertiaryButton' ||
                                          type === 'tertiaryDarkButton'
                                              ? hoverStyle['svg-start']
                                              : ''
                                      }`
                                    : ''
                            }`}
                            size={size}
                            isGatsby={link && link.slug ? true : false}
                            isSubmit={isSubmit}
                            disabled={disabled}
                            Tag={
                                isSubmit
                                    ? 'button'
                                    : downloadLink?.file
                                    ? 'a'
                                    : Tag
                                    ? Tag
                                    : 'a'
                            }
                            href={
                                href
                                    ? href
                                    : link && link.slug
                                    ? `/${link.slug}/`
                                    : externalLink
                                    ? externalLink
                                    : downloadLink?.file
                                    ? downloadLink.file?.publicURL
                                    : '#'
                            }
                            target={target ? target : '_self'}
                            {...(downloadLink &&
                                downloadLink?.basename && {
                                    download: downloadLink?.basename,
                                })}
                            {...props}>
                            {type === 'secondaryButton' ||
                            type === 'secondaryLightButton' ? (
                                <>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: label,
                                        }}
                                    />
                                    {size === 'sm' ? <SmallArrow /> : <Arrow />}
                                </>
                            ) : type === 'tertiaryButton' ||
                              type === 'tertiaryDarkButton' ? (
                                <>
                                    <SmallArrowLeft></SmallArrowLeft>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: label,
                                        }}
                                    />
                                </>
                            ) : (
                                label
                            )}
                        </Button>
                    ),
                )}
            </>
        );
    },
);

ButtonComponent.propTypes = {
    link: PropTypes.shape({
        slug: PropTypes.string,
    }),
    externalLink: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
};

export default ButtonComponent;
