import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import style from './style.module.scss';

function Bar({}, ref) {
    const progressContainerRef = useRef(null);
    const progressRef = useRef(null);
    const parentRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            parentRef: parentRef,
            progressRef: progressRef,
            progressContainerRef: progressContainerRef,
        }),
        [progressContainerRef, progressRef],
    );

    return (
        <div ref={parentRef} className={style.parent}>
            <div className={style.progressBar}>
                <div
                    className={style.progressContainer}
                    ref={progressContainerRef}>
                    <div ref={progressRef} className={style.progress} />
                </div>
            </div>
        </div>
    );
}

export default forwardRef(Bar);
