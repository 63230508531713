import React from 'react';
import PropTypes from 'prop-types';

import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

function Tag({ label, dark = false, active = false, slug, ...props }) {
    return (
        <ButtonComponent
            button={{
                ...props,
                link: { slug },
                label: label,
                type: dark ? 'tagDarkButton' : 'tagButton',
                className: [dark && style.dark, active && style.active].join(
                    ' ',
                ),
            }}
        />
    );
}

Tag.propTypes = {
    label: PropTypes.string,
    dark: PropTypes.bool,
    active: PropTypes.bool,
    slug: PropTypes.string,
};

export default Tag;
