import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import HubspotForm from '../HubspotForm';

import {
    Container,
    Section,
    Row,
    Col,
    Card,
    Heading,
    Text,
    Spinner,
    className,
} from '@solublestudio/react-bootstrap-components';

import TitleGradient from '../TitleGradient';
import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';
import style from './style.module.scss';
import CardVideo from '../CardVideo';

const WrapperSpinner = () => {
    return (
        <div {...className('d-flex justify-content-center')}>
            <Spinner variant="primary-900" />
        </div>
    );
};

function FormSection(
    {
        hubspotFormId,
        hubspotPortalId,
        titleGradient,
        subtitle,
        background = null,
        parentPage,
        fromHubspot,
        questions,
        logos,
        video,
        videoImage,
        smallTitle,
    },
    ref,
) {
    return (
        <Section
            ref={ref}
            className={`position-relative ${style.section} ${
                !background && style.bgDark
            }`}>
            {background && (
                <ImageComponent image={background} className={style.bg} />
            )}
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 5 }}>
                        <div className={style.stickySection}>
                            {!!parentPage && (
                                <ButtonComponent
                                    button={{
                                        label: parentPage.title,
                                        link: { slug: parentPage.slug },
                                        type: 'tertiaryDarkButton',
                                        className: 'mb-3 mb-lg-5',
                                    }}
                                />
                            )}
                            {titleGradient && (
                                <TitleGradient
                                    {...titleGradient}
                                    display={smallTitle && 'heading1'}
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-0`}
                                />
                            )}
                            {subtitle && (
                                <Text
                                    className={`normal text-primary-100 mt-3 mt-lg-5 mb-6 ${
                                        !logos && 'mb-lg-0'
                                    }`}
                                    addBreaklines={false}
                                    tag="div"
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-200`}>
                                    {subtitle}
                                </Text>
                            )}
                            {questions &&
                                questions.map((q, index) => {
                                    return (
                                        <div key={index} {...className('mb-3')}>
                                            <Heading
                                                tag="h4"
                                                className="button text-primary-100 mb-1">
                                                {q.question}
                                            </Heading>
                                            <Text
                                                tag="div"
                                                className="normal text-primary-400">
                                                {q.answer}
                                            </Text>
                                        </div>
                                    );
                                })}
                            {logos && (
                                <div
                                    {...className(
                                        `mb-6 mb-lg-0 ${style.logos}`,
                                    )}>
                                    {logos.map((logo, index) => {
                                        return (
                                            <ImageComponent
                                                image={logo}
                                                key={index}
                                                className={style.logo}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                            {video && (
                                <CardVideo
                                    wrapperClassName={`mb-6 mb-lg-0 pb-lg-5 mt-lg-6 ${style.videoContainer}`}
                                    video={video}
                                    background={videoImage}
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-400`}
                                />
                            )}
                        </div>
                    </Col>
                    <Col col={{ xs: 12, lg: 6 }} offset={{ lg: 1 }}>
                        <Card wrapperClassName={['mb-5'].join(' ')}>
                            <HubspotForm
                                fromHubspot={fromHubspot}
                                formId={hubspotFormId}
                                portalId={hubspotPortalId}
                                submitBlock
                                loading={<WrapperSpinner />}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

FormSection.propTypes = {
    hubspotFormId: PropTypes.string,
    hubspotPortalId: PropTypes.string,
    titleGradient: PropTypes.shape(TitleGradient.propTypes),
    subtitle: PropTypes.string,
    background: PropTypes.any,
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string,
            answer: PropTypes.string,
        }),
    ),
    logos: PropTypes.arrayOf(PropTypes.string),
    smallTitle: PropTypes.bool,
};

export default forwardRef(FormSection);
