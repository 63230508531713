import React, { useEffect, useRef } from 'react';

import style from './style.module.scss';

import { Text, className } from '@solublestudio/react-bootstrap-components';

import HexagonGradient from './HexagonGradient';

export default function FeatureSteps({ steps = [] }) {
    const containerRef = useRef();

    useEffect(() => {
        function onScroll() {
            if (!window || !containerRef.current) {
                return;
            }

            const progressCompleted = document.querySelector(
                '[data-progress-completed="true"]',
            );

            if (progressCompleted) {
                const progressRefCompleted = progressCompleted.getAttribute(
                    'data-progress-completed',
                );

                if (progressRefCompleted == 'true') {
                    containerRef.current.setAttribute(
                        'data-animation-start',
                        true,
                    );
                }
            }
        }
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div {...className(`d-none d-lg-block ${style.container}`)}>
            <div className={style.parent} ref={containerRef}>
                <div className={style.wrapperItems}>
                    {steps.length > 0 &&
                        steps.map((step, i) => (
                            <div
                                className={style.item}
                                key={`ft-hexagon-${i}`}
                                data-index={`${i + 1}`}>
                                <HexagonGradient>
                                    <Text
                                        className={[
                                            style.label,
                                            'extraSmall',
                                            'text-primary-100',
                                        ].join(' ')}>
                                        {step}
                                    </Text>
                                </HexagonGradient>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
