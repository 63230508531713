import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import FeatureIconComponent from '../FeatureIconComponent';

import style from './style.module.scss';
function FeaturesIconsSection({
    title,
    featureIconsTitle,
    subtitle,
    features,
    className,
    light,
    ...props
}) {
    return (
        <Section
            {...(light && { 'data-light': 'true' })}
            className={`${light ? 'bg-primary-200' : 'bg-primary-1000'} ${
                className ? className : ''
            } ${
                props.nextSectionType === 'DatoCmsFeaturesIconsSection'
                    ? style.resetPaddingBottom
                    : ''
            }`}>
            <Container>
                {title ? (
                    <Heading
                        tag="h2"
                        className={`${
                            light ? 'text-primary-900' : 'text-primary-100'
                        } display3 text-center mb-5 mb-xxl-8`}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-0`}>
                        {title}
                    </Heading>
                ) : null}
                {featureIconsTitle || subtitle ? (
                    <Row>
                        <Col
                            col={{ xs: 12, lg: 6, xxl: 5 }}
                            offset={{ lg: features?.length ? 0 : 1 }}>
                            {featureIconsTitle && (
                                <Heading
                                    tag="h2"
                                    className="heading1 text-primary-100 mb-3 mb-xxl-5"
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-0`}>
                                    {featureIconsTitle}
                                </Heading>
                            )}
                            {subtitle && (
                                <Text
                                    tag="div"
                                    className="normal text-primary-400 mb-8 mb-lg-7 mb-xxl-8"
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-200`}>
                                    {subtitle}
                                </Text>
                            )}
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    <Col
                        col={{ xs: 12, lg: 10 }}
                        offset={{ lg: 1 }}
                        className={style.featureIcons}>
                        {features.map((feature, i) => {
                            return (
                                <FeatureIconComponent
                                    key={`ft-icon-${i}`}
                                    {...feature}
                                    light={light}
                                    delay={i * 200}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

FeaturesIconsSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    features: PropTypes.arrayOf(
        PropTypes.shape(FeatureIconComponent.propTypes),
    ),
};

export default FeaturesIconsSection;
