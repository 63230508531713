export * from '@solublestudio/react-bootstrap-components';

// Alias
export { default as Button } from './src/components/ButtonComponent';
export { default as CtaSection } from 'CtaSection';
export { default as HeroSection } from 'HeroSection';
export { default as InternalHero } from 'InternalHero';
export { default as FormSection } from 'FormSection';
export { default as Header } from 'Header';
export { default as Footer } from 'Footer';
export { default as Link, navigate } from 'Link';
export { default as BlogSection } from 'BlogSection';
export { default as NotFoundPage } from 'NotFoundPage';
export { default as TextHeroSection } from 'TextHeroSection';

export { default as BenefitsSection } from './src/components/BenefitsSection';
export { default as InfoCardsSection } from './src/components/InfoCardsSection';
export { default as CtaSectionAlt } from './src/components/CtaSectionAlt';
export { default as CtaBannersSection } from './src/components/CtaBannersSection';
export { default as FeatureSection } from './src/components/FeatureSection';
export { default as FeaturesIconsSection } from './src/components/FeaturesIconsSection';
export { default as FeatureCategorySection } from './src/components/FeatureCategorySection';
export { default as HeroSectionAlt } from './src/components/HeroSectionAlt';
export { default as KnowUsSection } from './src/components/KnowUsSection';
export { default as HTMLTextSection } from './src/components/HTMLTextSection';
export { default as ProcessSection } from './src/components/ProcessSection';
export { default as ProductSection } from './src/components/ProductSection';
export { default as ServiceButtonSection } from './src/components/ServiceButtonSection';
export { default as StepSection } from './src/components/StepSection';
export { default as TextImageSection } from './src/components/TextImageSection';
export { default as TitleSection } from './src/components/TitleSection';
export { default as IntroTextSection } from './src/components/IntroTextSection';
export { default as IframeSection } from './src/components/IframeSection';
export { default as BlogDetail } from './src/components/BlogDetail';
export { default as CardListSection } from './src/components/CardListSection';
export { default as CardListPaginationSection } from './src/components/CardListPaginationSection';
export { default as OtherResourcesSection } from './src/components/OtherResourcesSection';
export { default as HighlightedResourceSection } from './src/components/HighlightedResourceSection';
export { default as QuoteSection } from './src/components/QuoteSection';
export { default as OpenPositionSection } from './src/components/OpenPositionSection';
export { default as StepsSection } from './src/components/StepsSection';
