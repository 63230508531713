import React from 'react';
import styles from './styles.module.scss';
import {
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';

export default function ServiceButtonComponent({
    preTitle,
    title,
    cta,
    background,
    delay = 0,
}) {
    return (
        <section
            className={styles.section}
            data-sal="slide-up"
            data-sal-delay={`xs-none--lg-${delay}`}>
            {background && (
                <ImageComponent className={styles.image} image={background} />
            )}
            <div
                {...className(
                    `${styles.serviceButtonContainer}  pb-4 pt-6 pt-lg-8 pl-lg-5 pr-lg-5 pl-3 pr-3`,
                )}>
                <div>
                    {preTitle && (
                        <Text
                            className={`small text-primary-100 mb-lg-5 mb-3`}
                            tag="div">
                            {preTitle}
                        </Text>
                    )}
                    <Heading tag="h3" className={`display3 text-primary-100`}>
                        {title}
                    </Heading>
                </div>
                <div>
                    {cta && (
                        <ButtonComponent
                            button={{
                                ...cta,
                                type: 'secondaryLightButton',
                                className: `mt-5 mt-xxl-8 `,
                            }}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}
