import React, { forwardRef, useMemo } from 'react';

import {
    className as classNameFnc,
    Input as RBCInput,
    Textarea as RBCTextarea,
    Select as RBCSelect,
    InputWithEraser as DSInputEraser,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

import { ReactComponent as Loupe } from '../../img/Loupe.svg';

import { ReactComponent as Info } from '../../img/Info.svg';
import { ReactComponent as Arrow } from '../../img/Arrow.svg';

export const InputEraser = forwardRef(
    (
        {
            label = '',
            error,
            className,
            wrapperClassName,
            dark = false,
            outline = false,
            ...props
        },
        ref,
    ) => {
        return (
            <DSInputEraser
                ref={ref}
                label={label}
                error={error}
                className={[
                    style.input,
                    className ? className : '',
                    error ? style.error : '',
                    outline ? style.outline : '',
                    dark ? style.dark : '',
                ].join(' ')}
                wrapperClassName={
                    classNameFnc(
                        [
                            'position-relative',
                            wrapperClassName ? wrapperClassName : '',
                            label ? style.withLabel : '',
                        ].join(' '),
                    ).className
                }
                labelClassName={classNameFnc(style.label).className}
                validatorClassName={classNameFnc(style.validator).className}
                eraserClassName={[style.eraser, dark && style.dark].join(' ')}
                previousContent={
                    <div
                        className={[style.previousContent, dark && style.dark]
                            .filter((el) => !!el)
                            .join(' ')}>
                        <Loupe />
                    </div>
                }
                laterContent={
                    <button
                        type="submit"
                        className={[style.laterContent, dark && style.dark]
                            .filter((el) => !!el)
                            .join(' ')}>
                        <Arrow />
                    </button>
                }
                {...props}
            />
        );
    },
);

const Input = forwardRef(
    (
        {
            label = '',
            info = false,
            error,
            className,
            wrapperClassName,
            textarea = false,
            outline = false,
            dark = false,
            options,
            ...props
        },
        ref,
    ) => {
        const Component = useMemo(
            () => (textarea ? RBCTextarea : options ? RBCSelect : RBCInput),
            [textarea, options],
        );

        return (
            <Component
                ref={ref}
                label={label}
                error={error}
                className={[
                    style.input,
                    className ? className : '',
                    error ? style.error : '',
                    textarea ? style.textarea : '',
                    outline ? style.outline : '',
                    dark ? style.dark : '',
                ].join(' ')}
                {...(info && { laterContent: <Info /> })}
                wrapperClassName={[
                    wrapperClassName ? wrapperClassName : '',
                    label ? style.withLabel : '',
                    info ? style.withInfo : '',
                ].join(' ')}
                labelClassName={classNameFnc(style.label).className}
                validatorClassName={classNameFnc(style.validator).className}
                options={options}
                {...props}
            />
        );
    },
);

export default Input;
