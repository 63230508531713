import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

function OpenPositionSection({ title, apiKeyId }) {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let scr = document.createElement('script'),
            head = document.head || document.getElementsByTagName('head')[0];

        scr.type = 'text/javascript';
        scr.src =
            'https://scripts.teamtailor-cdn.com/widgets/production/jobs.js';
        scr.async = true;

        head.insertBefore(scr, head.firstChild);

        return () => {
            scr.remove();
            if (typeof window.TeamTailor !== 'undefined') {
                delete window.TeamTailor;
            }
            if (typeof window.TEAMTAILOR_JOB_SCRIPT_LOADED !== 'undefined') {
                delete window.TEAMTAILOR_JOB_SCRIPT_LOADED;
            }
        };
    }, []);

    return (
        <Section id="jobs" data-light={true}>
            <Container>
                <Row>
                    <Col>
                        <Heading className="display3 text-primary-900 mb-11 mb-lg-5">
                            {title}
                        </Heading>
                        <div
                            className={style.wrapper}
                            dangerouslySetInnerHTML={{
                                __html: `<div class="teamtailor-jobs-widget" data-teamtailor-limit="8" data-teamtailor-pagination="true" data-teamtailor-popup="true" data-teamtailor-department-select="true" data-teamtailor-role-select="true" data-teamtailor-region-select="true" data-teamtailor-location-select="true" data-teamtailor-api-key="${apiKeyId}"></div>`,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

OpenPositionSection.propTypes = {
    title: PropTypes.string,
};

export default OpenPositionSection;
