import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';

function TextHeroSection({
    title,
    titleTag,
    description,
    background,
    nextSectionType,
}) {
    return (
        <Section
            className={[
                style.section,
                [
                    'DatoCmsWhitePaperFullSection',
                    'DatoCmsSuccessStoriesFullSection',
                    'DatoCmsWebinarFullSection',
                    'DatoCmsNextEventsSection',
                    'DatoCmsPreviusEventsSection',
                ].indexOf(nextSectionType) !== -1
                    ? style.nextSectionOver
                    : '',
            ].join(' ')}>
            {background && (
                <ImageComponent
                    className={style.backgroundImage}
                    image={background}
                />
            )}
            <Container>
                <Row>
                    <Col className="text-center">
                        <Heading
                            tag={titleTag}
                            className="display1 text-primary-100">
                            {title}
                        </Heading>
                    </Col>
                    <Col
                        col={{ xs: 12, lg: 6 }}
                        offset={{ lg: 3 }}
                        className="text-center">
                        <Text
                            tag="div"
                            className="normal text-primary-100 mt-3 mt-lg-4 mt-xxl-5">
                            {description}
                        </Text>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

TextHeroSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    background: PropTypes.any,
    nextSectionType: PropTypes.string,
};

export default TextHeroSection;
