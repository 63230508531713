import React, { useMemo, useCallback } from 'react';

import {
    Section,
    Container,
    Row,
    Col,
    Text,
    className,
    useIsMobile,
} from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../ImageComponent';
import Link from 'Link';
import Input from '../Input';
import SocialLinks from '../BlogDetail/components/SocialLinks';

import style from './style.module.scss';

function Footer({
    logo,
    bdeoInfo,
    footerLinks,
    copyright,
    legalLinks,
    languages,
    socialLinks,
    hideNavs,
    footerLogos = [],
    footerText,
}) {
    const isMobile = useIsMobile();

    const infoText = useMemo(() => {
        if (!bdeoInfo) {
            return [];
        }

        let parts = bdeoInfo
            .split('</p>')
            .map((i) => i.replace('<p>', '').trim())
            .filter((i) => !!i);

        return parts.map((part) =>
            part
                .split(`\n`)
                .map((i) => i.trim())
                .filter((i) => !!i)
                .join(isMobile ? ' &nbsp;ı&nbsp; ' : `\n`),
        );
    }, [bdeoInfo, isMobile]);

    const social = useCallback(
        (socialLinks) => {
            if (!socialLinks?.length) {
                return {};
            }

            let data = {};

            socialLinks.forEach((el, i) => {
                return (data[el.title] = el.href);
            });

            return data;
        },
        [socialLinks],
    );

    return (
        <Section
            data-light
            Tag="footer"
            className={`${style.footer} bg-primary-100`}>
            {hideNavs ? null : (
                <Container>
                    <Row pt={{ xs: 6, lg: 5, xxl: 7 }} pb={{ lg: 5 }}>
                        <Col col={{ lg: 2 }} mb={{ xs: 3, lg: 0 }}>
                            <ImageComponent
                                image={logo}
                                className={`mb-3 mb-lg-4 ${style.logo}`}></ImageComponent>
                            <div className={style.info}>
                                {infoText.map((it, i) => (
                                    <Text key={i} tag="p" className="small">
                                        {it}
                                    </Text>
                                ))}
                            </div>
                            <SocialLinks
                                light={true}
                                className={style.footerSocialLinks}
                                links={social(socialLinks)}></SocialLinks>
                        </Col>
                        {footerLinks.map((col, i) => {
                            if (!!col?.length) {
                                return (
                                    <Col
                                        key={i}
                                        col={{ xs: 12, lg: !i ? 4 : 2 }}>
                                        <ul
                                            {...className(
                                                `list-unstyled mb-3 mb-lg-0`,
                                            )}>
                                            {col.map((link, index) => (
                                                <li
                                                    key={`${i}-${index}`}
                                                    {...className(
                                                        `mb-1 ${style.linkLi} ${
                                                            link.detached
                                                                ? style.linkLiDetached
                                                                : ''
                                                        }`,
                                                    )}>
                                                    {link.href ? (
                                                        <Link
                                                            title={link.title}
                                                            href={link.href}
                                                            target={link.target}
                                                            {...className(
                                                                `smallMono ${
                                                                    link.detached
                                                                        ? style.infoLinks
                                                                        : style.links
                                                                }`,
                                                            )}>
                                                            {link.title}
                                                        </Link>
                                                    ) : (
                                                        <span
                                                            {...className(
                                                                `smallMono text-primary-900`,
                                                            )}>
                                                            {link.title}
                                                        </span>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                );
                            }

                            return null;
                        })}

                        {(footerLogos?.length > 0 || footerText) && (
                            <Col col={{ xs: 12, lg: 4 }} offset={{ lg: 2 }}>
                                <ul
                                    {...className(
                                        `list-inline ${style.footerListLogos}`,
                                    )}>
                                    {footerLogos.map((image, i) => (
                                        <li
                                            {...className(
                                                [
                                                    'list-inline-item',
                                                    style.footerImage,
                                                ].join(' '),
                                            )}
                                            key={`footer-logo-${i}`}>
                                            <ImageComponent image={image} />
                                        </li>
                                    ))}
                                </ul>
                                {footerText && (
                                    <Text
                                        className="small text-primary-600"
                                        tag="div">
                                        {footerText}
                                    </Text>
                                )}
                            </Col>
                        )}
                    </Row>
                </Container>
            )}
            <div
                {...className(
                    `bg-primary-200 pt-3 pb-3 pt-lg-2 pb-lg-2 ${style.footerInfo}`,
                )}>
                <Container>
                    <Row>
                        <Col col={{ lg: 10 }} order={{ lg: 2 }}>
                            <div {...className(`d-lg-flex flex-row-reverse`)}>
                                {legalLinks.map((ll, i) => (
                                    <div
                                        key={i}
                                        {...className(
                                            `mb-2 mb-lg-0 ${style.legalLinkWrapper}`,
                                        )}>
                                        <Link
                                            href={ll.href}
                                            target={ll.target}
                                            {...className(
                                                `small ${style.links}`,
                                            )}>
                                            {ll.title}
                                        </Link>
                                    </div>
                                ))}
                                {languages ? (
                                    <Input
                                        value={languages.current}
                                        options={languages.options}
                                        wrapperClassName={style.selectContainer}
                                        className={style.selectLanguage}
                                        onChange={
                                            languages.onChange
                                                ? (e) => {
                                                      languages.onChange(
                                                          e.target.options[
                                                              e.target
                                                                  .selectedIndex
                                                          ].value,
                                                      );
                                                  }
                                                : null
                                        }
                                    />
                                ) : null}
                            </div>
                        </Col>
                        <Col order={{ lg: 1 }} col={{ lg: 2 }}>
                            <Text className="small text-primary-600">
                                {copyright}
                            </Text>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Section>
    );
}

Footer.propTypes = {};

export default Footer;
