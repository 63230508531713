import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';
import TitleGradient from '../TitleGradient';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';
function CtaSectionAlt({ preTitle, titleGradient, cta }) {
    return (
        <Section data-light className={style.section}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 10, xxl: 8 }}
                        offset={{ lg: 1, xxl: 2 }}>
                        <Heading
                            className="smallMono text-primary-600"
                            tag="h4">
                            {preTitle}
                        </Heading>
                        <TitleGradient
                            {...titleGradient}
                            className="mb-2 mt-2 mb-xxl-3 mt-xxl-3"
                        />
                        <ButtonComponent
                            button={{
                                ...cta,
                                type: 'secondaryButton',
                            }}></ButtonComponent>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

CtaSectionAlt.propTypes = {
    preTitle: PropTypes.string,
    titleGradient: PropTypes.shape(TitleGradient.propTypes),
    cta: PropTypes.object,
};

export default CtaSectionAlt;
