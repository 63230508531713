import React from 'react';
import PropTypes from 'prop-types';
import {
    Section,
    Container,
    Row,
    Col,
    Text,
    Spinner,
    className,
} from '@solublestudio/react-bootstrap-components';

import ResourceCard from '../ResourceCard';
import Pagination from '../Pagination';

import style from './style.module.scss';

function CardListPaginationSection({
    lgCardColumns,
    xxlCardColumns,
    cards,
    paginationParams = {},
    highlighted,
    sectionClassName,
    emptyText,
    isLoading,
    loadingText,
}) {
    const {
        pages = 1,
        firstPageHref = '/',
        pageHref = '/',
        activePage = 1,
        LinkTag,
    } = paginationParams;

    return (
        <Section
            data-light="true"
            className={`${style.section} ${sectionClassName}`}>
            <Container>
                <Row className="mt-5 mt-xxl-8">
                    {!isLoading ? (
                        <>
                            {cards?.length > 0 ? (
                                cards.map(({ horizontal, ...card }, index) => {
                                    const isHighlighted =
                                        highlighted && index === 0;

                                    return (
                                        <Col
                                            key={`cardlistpagination-${index}`}
                                            col={{
                                                xs: 12,
                                                lg: isHighlighted
                                                    ? 12
                                                    : lgCardColumns,
                                                xxl: isHighlighted
                                                    ? 12
                                                    : xxlCardColumns,
                                            }}
                                            className={`mb-3 mb-lg-5`}>
                                            <ResourceCard
                                                {...card}
                                                isHighlighted={isHighlighted}
                                                horizontal={
                                                    isHighlighted
                                                        ? {
                                                              ...horizontal,
                                                              xs: false,
                                                          }
                                                        : horizontal
                                                }
                                            />
                                        </Col>
                                    );
                                })
                            ) : !!emptyText ? (
                                <Col
                                    col={{ xs: 12, lg: 10 }}
                                    offset={{ lg: 1 }}
                                    className="mt-10 mt-lg-15 pt-8 pt-lg-10">
                                    <Text
                                        className="large text-primary-600"
                                        tag="div">
                                        {emptyText}
                                    </Text>
                                </Col>
                            ) : null}
                        </>
                    ) : (
                        <div
                            {...className(
                                'mt-10 mt-lg-15 pt-8 pt-lg-10 d-flex justify-content-center align-items-center w-100 flex-column',
                            )}>
                            <Spinner variant="text-primary-600" />
                            {loadingText && (
                                <Text
                                    className="normal text-primary-600 mt-3"
                                    tag="div">
                                    {loadingText}
                                </Text>
                            )}
                        </div>
                    )}
                </Row>
                {pages > 1 ? (
                    <Row>
                        <Col>
                            <Pagination
                                pages={pages}
                                firstPageHref={firstPageHref}
                                pageHref={pageHref}
                                activePage={activePage}
                                LinkTag={LinkTag}
                            />
                        </Col>
                    </Row>
                ) : null}
            </Container>
        </Section>
    );
}

CardListPaginationSection.propTypes = {
    lgCardColumns: PropTypes.number,
    xxlCardColumns: PropTypes.number,
    cards: PropTypes.arrayOf(PropTypes.shape(ResourceCard.propTypes)),
    paginationParams: PropTypes.object,
    highlighted: PropTypes.bool,
    emptyText: PropTypes.string,
    isLoadind: PropTypes.bool,
};

export default CardListPaginationSection;
