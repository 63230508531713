import React from 'react';
import PropTypes from 'prop-types';

import {
    Col,
    Container,
    Heading,
    Row,
    Section,
} from '@solublestudio/react-bootstrap-components';

import OtherResourceCard from 'OtherResourceCard';

function OtherResourcesSection({ title, pages, background, label }) {
    return (
        <Section data-light="true">
            <Container>
                <Row>
                    {title && (
                        <Col col={{ xs: 12 }}>
                            <Heading
                                className="display3 text-primary-900 mb-5"
                                tag="h3">
                                {title}
                            </Heading>
                        </Col>
                    )}
                    {pages?.length > 0 &&
                        pages.map(({ title, slug }, i) => (
                            <Col
                                col={{ xs: 12, lg: 4 }}
                                key={`other-resource-${slug}`}
                                className={
                                    i !== pages?.length - 1 && 'mb-3 mb-lg-0'
                                }>
                                <OtherResourceCard
                                    title={title}
                                    background={background}
                                    cta={{
                                        label: label,
                                        link: {
                                            slug,
                                        },
                                    }}
                                />
                            </Col>
                        ))}
                </Row>
            </Container>
        </Section>
    );
}

OtherResourcesSection.propTypes = {};

export default OtherResourcesSection;
