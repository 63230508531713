import React, {
    useMemo,
    useRef,
    useCallback,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Col,
    Row,
    className,
} from '@solublestudio/react-bootstrap-components';

import ProcessStep from './components/ProcessStep';

import { ReactComponent as Hexagon } from '../../img/Hexagon.svg';
import { ReactComponent as HexagonFill } from '../../img/HexagonFill.svg';

import TitleGradient from '../TitleGradient';

import style from './style.module.scss';

const DURATION = 3000;

function ProcessSection({ steps, active }) {
    const [startAnimation, setStartAnimation] = useState(active);
    const progressRef = useRef(null);
    const barRef = useRef(null);

    const hasTwoItems = useMemo(() => steps && steps?.length === 2, [steps]);
    const hasOneItem = useMemo(() => steps && steps?.length === 1, [steps]);

    const getGradientNumber = useCallback((number, active = false) => {
        return active ? `<b>${number}</b>` : number;
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const onSalIn = ({ detail }) => {
            if (detail.target.dataset.progress) {
                setTimeout(() => {
                    setStartAnimation(true);
                }, 300);
            }
        };

        window.document.addEventListener('sal:in', onSalIn);
        return () => {
            window.document.removeEventListener('sal:in', onSalIn);
        };
    }, []);

    return (
        <Section
            data-light
            data-progress
            data-sal="slide-up"
            className={[style.section, 'bg-primary-100'].join(' ')}>
            <div
                className={[
                    style.barContainer,
                    startAnimation ? style.active : '',
                    hasTwoItems ? style['barContainer--items-2'] : '',
                    hasOneItem ? style['barContainer--items-1'] : '',
                ].join(' ')}
                style={{
                    '--duration': `${DURATION}ms`,
                }}>
                <div className={[style.bar].join(' ')} ref={barRef}>
                    <div className={style.progress} ref={progressRef} />
                </div>
                <Container>
                    <Row>
                        {steps?.length > 0 &&
                            steps.map((step, i) => {
                                const isLast = steps.length - 1 === i;

                                const title = step?.titleNode
                                    ? step.titleNode?.contentWithoutWrapper
                                    : step?.title;

                                return (
                                    <Col
                                        className={[
                                            style.item,
                                            !isLast && 'pb-6',
                                            'mb-0',
                                            'd-flex',
                                        ].join(' ')}
                                        data-process-step={`${i + 1}`}
                                        key={`process-step-${i}`}
                                        col={{
                                            xs: 12,
                                            lg: 4,
                                            xxl: 4,
                                        }}
                                        offset={{
                                            lg: hasOneItem
                                                ? 4
                                                : hasTwoItems
                                                ? i === 0
                                                    ? 2
                                                    : 1
                                                : 0,
                                        }}>
                                        <div className={style.wrapperIcon}>
                                            <TitleGradient
                                                title={getGradientNumber(
                                                    step?.number,
                                                    false,
                                                )}
                                                titleTag="p"
                                                colorPattern={null}
                                                className={[
                                                    style.index,
                                                    style.inactiveItem,
                                                    'small',
                                                    'ml-1',
                                                    'ml-lg-0',
                                                    'text-primary-600',
                                                ].join(' ')}
                                            />
                                            <TitleGradient
                                                title={getGradientNumber(
                                                    step?.number,
                                                    true,
                                                )}
                                                titleTag="p"
                                                colorPattern="blue"
                                                className={[
                                                    style.index,
                                                    style.activeItem,
                                                    'small',
                                                    'ml-1',
                                                    'ml-lg-0',
                                                    'text-primary-600',
                                                ].join(' ')}
                                            />

                                            <div className={style.icon}>
                                                <Hexagon
                                                    className={[
                                                        style.hexagon,
                                                        style.inactiveItem,
                                                    ].join(' ')}
                                                />
                                                <HexagonFill
                                                    className={[
                                                        style.hexagon,
                                                        style.activeItem,
                                                    ].join(' ')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            {...className(
                                                [
                                                    'mb-4',
                                                    'pt-lg-4',
                                                    'pl-8',
                                                    'pl-lg-0',
                                                ].join(' '),
                                            )}>
                                            <ProcessStep
                                                title={title}
                                                description={step?.description}
                                                colorPattern="blue"
                                                classNameTitle={style.content}
                                                inactiveClassName={
                                                    style.inactiveItem
                                                }
                                                activeClassName={
                                                    style.activeItem
                                                }
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </div>
        </Section>
    );
}

ProcessSection.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            titleGradient: PropTypes.any,
            description: PropTypes.string,
        }),
    ),
    active: PropTypes.bool,
};

export default ProcessSection;
