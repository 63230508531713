import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';
function IntroTextSection({ description }) {
    return (
        <Section data-light className={style.section}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 8 }}
                        offset={{ lg: 2 }}
                        className="text-center">
                        <Heading
                            tag="div"
                            className={`${style.heading} large text-primary-900 mt-8 mb-8 mt-lg-8 mb-lg-8 mt-xxl-15 mb-xxl-15`}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-600`}>
                            {description}
                        </Heading>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

IntroTextSection.propTypes = {
    description: PropTypes.string,
};

export default IntroTextSection;
