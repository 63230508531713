import React, { forwardRef, useMemo } from 'react';

import {
    className as classNameFnc,
    Select as RBCSelect,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Select = forwardRef(
    (
        {
            label = '',
            error,
            className,
            wrapperClassName,
            options = false,
            outline = false,
            dark = false,
            ...props
        },
        ref,
    ) => {
        return (
            <RBCSelect
                ref={ref}
                label={label}
                error={error}
                className={[
                    style.input,
                    style.select,
                    className ? className : '',
                    error ? style.error : '',
                    outline ? style.outline : '',
                    dark ? style.dark : '',
                ].join(' ')}
                wrapperClassName={[
                    wrapperClassName ? wrapperClassName : '',
                ].join(' ')}
                labelClassName={classNameFnc(style.label).className}
                validatorClassName={classNameFnc(style.validator).className}
                options={options}
                {...props}
            />
        );
    },
);

export default Select;
