import { Card } from '@solublestudio/react-bootstrap-components';
import React from 'react';

import ImageComponent from '../ImageComponent';

import style from './style.module.scss';

function CardBG({ background, children, wrapperClassName, bodyClassName }) {
    return (
        <Card
            wrapperClassName={`${wrapperClassName ? wrapperClassName : ''} ${
                style.wrapper
            }`}
            bodyClassName={`${bodyClassName ? bodyClassName : ''} ${
                style.body
            }`}>
            {background && (
                <ImageComponent image={background} className={style.image} />
            )}
            {children}
        </Card>
    );
}

export default CardBG;
