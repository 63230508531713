import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FormSection from '../../FormSection';

export default function GatsbyFormSection(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "not-found-bg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return <FormSection {...props} background={{ file: data.bg }} />;
}
