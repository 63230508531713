import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Link from 'Link';

import Header from '../../Header';

export default function GatsbyHeader(props) {
    const data = useStaticQuery(graphql`
        {
            logo: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "logo" }
            ) {
                publicURL
            }
            logoWhite: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "logo-white" }
            ) {
                publicURL
            }
        }
    `);

    return (
        <Header
            logo={data.logo.publicURL}
            logoWhite={data.logoWhite.publicURL}
            Tag={Link}
            scheme="gatsby"
            {...props}
        />
    );
}
