import React from 'react';
import PropTypes from 'prop-types';

import { StructuredText } from 'react-datocms';

import { className } from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../../../ButtonComponent';
import ImageComponent from '../../../ImageComponent';
import EmbedVideo from '../../../EmbedVideo';

import Link from 'Link';

import style from './style.module.scss';

function HTMLComponent({ ...props }) {
    return (
        <div
            className={[
                props.className ? props.className : '',
                style.content,
            ].join(' ')}
            {...(props.children
                ? { dangerouslySetInnerHTML: { __html: props.children } }
                : {})}>
            <StructuredText
                data={props.content}
                renderLinkToRecord={({ record }) => {
                    switch (record.__typename) {
                        case 'DatoCmsPage':
                        case 'DatoCmsEvent':
                        case 'DatoCmsBlog':
                        case 'DatoCmsSuccessStory':
                        case 'DatoCmsWhitepaper':
                            return (
                                <Link
                                    href={record?.slug}
                                    title={record.title}
                                    alt={record?.title}>
                                    {record.title}
                                </Link>
                            );
                        default:
                            return null;
                    }
                }}
                renderInlineRecord={({ record }) => {
                    switch (record.__typename) {
                        case 'DatoCmsButton':
                            return (
                                <ButtonComponent
                                    button={{
                                        ...record,
                                        className: style.btn,
                                        type: 'primaryDarkButton',
                                    }}
                                />
                            );
                        default:
                            return null;
                    }
                }}
                renderBlock={({ record }) => {
                    switch (record.__typename) {
                        case 'DatoCmsImage':
                            const classes = className(
                                [
                                    'img-fluid',
                                    style.image,
                                    record?.size && style[record?.size],
                                    record?.isSmall ? style.imageSmall : '',
                                ]
                                    .filter(Boolean)
                                    .join(' '),
                            );
                            let item = record.image?.file ? (
                                record.image?.file?.childImageSharp ? (
                                    <ImageComponent
                                        alt={record.image.alt}
                                        image={record.image}
                                        {...classes}
                                    />
                                ) : record.image?.file?.publicURL ? (
                                    <img
                                        {...classes}
                                        alt={record.image.alt}
                                        src={record.image.file.publicURL}
                                    />
                                ) : null
                            ) : record.externalSource ? (
                                <img {...classes} src={record.externalSource} />
                            ) : null;

                            if (!item) {
                                return null;
                            }

                            return record.link?.file?.publicURL ||
                                record.externalLink ? (
                                <a
                                    href={
                                        record.link?.file?.publicURL ||
                                        record.externalLink
                                    }
                                    target="_blank"
                                    rel={
                                        record.externalLink
                                            ? 'noreferrer noopener'
                                            : ''
                                    }>
                                    {item}
                                </a>
                            ) : (
                                item
                            );
                        case 'DatoCmsEmbedVideo':
                            const { providerUid } = record?.url ?? {};
                            return (
                                <div className={[
                                    style.video,
                                    record?.size && style[record?.size]
                                ].join(' ')}
                                >
                                    <EmbedVideo providerUid={providerUid} />
                                </div>
                            );
                        default:
                            return null;
                    }
                }}
            />
        </div>
    );
}

HTMLComponent.propTypes = {
    content: PropTypes.shape({}),
};

export default HTMLComponent;
