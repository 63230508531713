import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
    Modal,
    useIsMobile,
    className,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';
import { ReactComponent as IconHexagonPlay } from '../../img/hexagon-play.svg';
import { ReactComponent as IconPlay } from '../../img/play.svg';

import style from './style.module.scss';

function CardVideo({
    background,
    video,
    wrapperClassName,
    cardClassName,
    ...props
}) {
    const refModal = useRef({});

    const [videoStarted, setVideoStarted] = useState(false);

    const mobileVideo = useRef(null);
    const desktopVideo = useRef(null);

    const isMobile = useIsMobile();

    const requestFullscreen = useCallback(() => {
        if (!mobileVideo?.current) {
            return;
        }

        const v = mobileVideo.current;

        if (v.requestFullscreen) {
            v.requestFullscreen();
        } else if (v.mozRequestFullScreen) {
            v.mozRequestFullScreen();
        } else if (v.webkitRequestFullscreen) {
            v.webkitRequestFullscreen();
        } else if (v.msRequestFullscreen) {
            v.msRequestFullscreen();
        } else if (v.webkitEnterFullscreen) {
            v.webkitEnterFullscreen();
        }
    }, [mobileVideo]);

    const onClickDetail = useCallback(
        (ev) => {
            ev.preventDefault();

            if (isMobile) {
                requestFullscreen();
                mobileVideo?.current?.play();
                return;
            }

            refModal?.current?.toggle();
            desktopVideo?.current?.play();
        },
        [isMobile, mobileVideo, refModal, desktopVideo],
    );

    useEffect(() => {
        if (
            !mobileVideo?.current ||
            typeof document === 'undefined' ||
            !desktopVideo?.current
        ) {
            return;
        }

        const onFullscreenChange = () => {
            if (!document.fullscreenElement) {
                mobileVideo?.current?.pause();
                desktopVideo?.current?.pause();
            }
        };

        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                onFullscreenChange,
            );
        };
    }, [mobileVideo, desktopVideo]);

    useEffect(() => {
        if (typeof window === 'undefined' || !desktopVideo?.current) {
            return;
        }

        const onPlay = (e) => {
            setVideoStarted(true);
        };

        desktopVideo?.current?.addEventListener('play', onPlay);

        return () => {
            desktopVideo?.current?.removeEventListener('play', onPlay);
        };
    }, [desktopVideo]);

    const onToggle = useCallback(
        (show) => {
            if (!show) {
                desktopVideo?.current?.pause();
            }
        },
        [desktopVideo],
    );

    return (
        <div
            {...className(
                `${style.wrapper} ${wrapperClassName ? wrapperClassName : ''}`,
            )}
            {...props}>
            <button
                onClick={onClickDetail}
                {...className(
                    `position-relative ${style.card} ${
                        cardClassName ? cardClassName : ''
                    }`,
                )}>
                <video ref={mobileVideo} controls={false}>
                    <source src={video.src} type={`video/${video.extension}`} />
                </video>
                {background && (
                    <ImageComponent
                        image={background}
                        className={style.image}
                    />
                )}
                <IconHexagonPlay />
            </button>
            <Modal
                ref={refModal}
                header
                dialogClassName={style.modalDialog}
                contentClassName={style.modalContent}
                bodyClassName={style.modalBody}
                headerClassName={style.modalHeader}
                onToggle={onToggle}
                closeButtonClassName={style.modalCloseButton}>
                <video
                    ref={desktopVideo}
                    className={style.video}
                    data-started={videoStarted}
                    controls>
                    <source src={video.src} type={`video/${video.extension}`} />
                </video>
                <IconPlay className={style.buttonPlay} />
            </Modal>
        </div>
    );
}

export default CardVideo;
