import React from 'react';

import getDataProps from '../../utils/getDataProps';

import CssClassNames from '../../scss/CssClassNames';
import moduledStyles from './styles.module.scss';

const { className } = new CssClassNames(moduledStyles, ['utility']);

const PATHS = {
    facebook:
        'M21.195 31.818h-4.434V21.362h-2.216V17.76h2.216v-2.163c0-2.94 1.25-4.688 4.805-4.688h2.96v3.604h-1.85c-1.384 0-1.475.504-1.475 1.443l-.006 1.804h3.35l-.392 3.602h-2.958v10.456z',
    twitter:
        'M26.842 14.587a4.1 4.1 0 0 0 1.721-2.284 7.596 7.596 0 0 1-2.488 1.002A3.813 3.813 0 0 0 23.217 12c-2.163 0-3.915 1.85-3.915 4.13 0 .324.032.64.1.941-3.255-.172-6.14-1.815-8.073-4.316a4.294 4.294 0 0 0-.53 2.078c0 1.432.691 2.697 1.742 3.439a3.777 3.777 0 0 1-1.775-.516v.05c0 2.002 1.35 3.672 3.144 4.05-.33.097-.675.146-1.034.146-.252 0-.498-.025-.736-.073.498 1.64 1.944 2.836 3.659 2.868A7.604 7.604 0 0 1 10 26.508a10.68 10.68 0 0 0 6.004 1.856c7.205 0 11.143-6.295 11.143-11.754 0-.18-.002-.358-.01-.534a8.187 8.187 0 0 0 1.954-2.139 7.507 7.507 0 0 1-2.249.65z',
    linkedin:
        'M11.459 16.83h3.543v12.35H11.46V16.83zm1.681-1.545h-.025c-1.283 0-2.115-.944-2.115-2.14 0-1.22.856-2.145 2.165-2.145 1.307 0 2.111.923 2.137 2.142 0 1.196-.83 2.143-2.162 2.143zm16.042 13.897h-4.019v-6.393c0-1.672-.628-2.813-2.01-2.813-1.058 0-1.646.77-1.92 1.513-.102.265-.086.637-.086 1.01v6.683h-3.98s.05-11.323 0-12.352h3.98v1.938c.235-.847 1.507-2.057 3.537-2.057 2.52 0 4.498 1.778 4.498 5.602v6.869z',
    youtube:
        'M29.182 23.604v-6.48s0-3.124-3.133-3.124H14.132S11 14 11 17.123v6.48s0 3.124 3.132 3.124H26.05s3.133 0 3.133-3.123m-5.561-3.231l-5.952 3.484v-6.97l5.952 3.486',
    instagram:
        'M24.7,30.9h-9.4c-3.5,0-6.3-2.8-6.3-6.3v-9.3C9,11.8,11.8,9,15.3,9h9.4c3.5,0,6.3,2.8,6.3,6.3v9.3C31,28.1,28.2,30.9,24.7,30.9z M15.3,11c-2.4,0-4.3,1.9-4.3,4.3v9.3c0,2.4,1.9,4.3,4.3,4.3h9.4c2.4,0,4.3-1.9,4.3-4.3v-9.3c0-2.4-1.9-4.3-4.3-4.3H15.3z M20,25.8c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8S23.2,25.8,20,25.8z M20,16.3c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8C23.8,18,22.1,16.3,20,16.3z M25.9,12.7c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4S25.1,12.7,25.9,12.7z',
    github:
        'M29.6,14.6c-1-1.7-2.4-3.1-4.1-4.1C23.9,9.5,22,9,19.9,9s-3.9,0.5-5.6,1.5c-1.7,1-3.1,2.4-4.1,4.1c-1,1.7-1.5,3.6-1.5,5.6c0,2.5,0.7,4.7,2.1,6.6s3.3,3.3,5.6,4c0.3,0.1,0.4,0,0.6-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3,0-0.8c0-0.5,0-1,0-1.3l-0.3,0.1c-0.2,0.1-0.4,0.1-0.8,0.1c-0.3,0-0.7-0.1-1-0.1c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.2-0.5-0.5-0.6-0.9l-0.2-0.3c-0.1-0.2-0.2-0.4-0.4-0.7c-0.2-0.3-0.4-0.4-0.6-0.6L12,25.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.4-0.1l0.3,0.1c0.2,0,0.4,0.1,0.7,0.3c0.3,0.2,0.5,0.4,0.7,0.8c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.6,0.3,1,0.3c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.1,0.7-0.2c0.1-0.7,0.3-1.2,0.7-1.5c-0.6-0.1-1.1-0.2-1.5-0.3c-0.4-0.1-0.9-0.3-1.3-0.6c-0.4-0.3-0.8-0.6-1.2-1c-0.3-0.4-0.6-0.9-0.8-1.5c-0.2-0.6-0.3-1.3-0.3-2.2c0-1.2,0.4-2.2,1.2-3c-0.3-0.9-0.3-1.9,0.1-3c0.3-0.1,0.7,0,1.2,0.2c0.6,0.2,1,0.4,1.2,0.6c0.3,0.2,0.4,0.3,0.6,0.4c0.9-0.3,1.9-0.4,2.8-0.4c1,0,1.9,0.1,2.8,0.4l0.6-0.3c0.4-0.2,0.8-0.4,1.3-0.6c0.5-0.2,0.9-0.2,1.2-0.2c0.4,1.1,0.5,2.1,0.1,3c0.8,0.8,1.2,1.9,1.2,3c0,0.8-0.1,1.6-0.3,2.2c-0.2,0.6-0.4,1.1-0.8,1.5c-0.3,0.4-0.7,0.7-1.2,1c-0.4,0.3-1,0.4-1.3,0.6c-0.4,0.1-1,0.2-1.5,0.3c0.5,0.4,0.8,1.1,0.8,2.1v3.1c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.6,0.1c2.2-0.7,4.1-2.1,5.6-4c1.4-2,2.1-4.1,2.1-6.6C31.2,18.2,30.7,16.3,29.6,14.6z',
    mail:
        'M29.907 12.593v14.814H9.26V12.593h20.648zm-19.168 2.26l.001 11.073h17.686V14.854l-8.843 6.92-8.844-6.921zm16.28-.78H12.146l7.436 5.82 7.435-5.82z',
    whatsapp:
        'M8 32l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z',
};

export const SocialIcons = ({
    icons = [],
    size = 40,
    padding = 0,
    linkClassName = '',
    wrapperClassName = '',
    ...props
}) => (
    <ul {...getDataProps(props)} {...className('wrapper', wrapperClassName)}>
        {icons.map(({ url = '#', kind = 'facebook' }, i) => (
            <li
                key={i}
                {...className(
                    `d-inline-block ${
                        i === icons.length - 1 ? 'mr-0' : `mr-${padding}`
                    }`,
                )}>
                <a
                    href={url}
                    {...className('link', linkClassName)}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={kind}>
                    <svg
                        width={size}
                        height={size}
                        viewBox={`0 0 ${size} ${size}`}>
                        <path
                            style={
                                parseInt(size) !== 40
                                    ? {
                                          transform: `scale(${
                                              parseInt(size) / 40
                                          })`,
                                      }
                                    : null
                            }
                            d={
                                PATHS[kind]
                                    ? PATHS[kind]
                                    : `M0 0h${size}v${size}H0z`
                            }
                        />
                    </svg>
                </a>
            </li>
        ))}
    </ul>
);
