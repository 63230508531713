import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Text,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';

import { ReactComponent as TopQuotes } from '../../img/topQuotes.svg';
import { ReactComponent as BottomQuotes } from '../../img/bottomQuotes.svg';
export default function QuoteSection({ text, logo, name, role }) {
    return (
        <Section data-light="true" className="bg-primary-200">
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 1 }}
                        offset={{ lg: 1 }}
                        order={{ lg: 1 }}
                        mb={{ xs: 3, lg: 0 }}>
                        <div className={style.topQuotes}>
                            <TopQuotes width="50"></TopQuotes>
                        </div>
                    </Col>
                    <Col col={{ xs: 12, lg: 8 }} order={{ lg: 2 }}>
                        <Text
                            className="large text-primary-900 text-center mb-4 mb-lg-6"
                            tag="blockquote">
                            {text}
                        </Text>
                    </Col>
                    <Col
                        col={{ xs: 12, lg: 6 }}
                        offset={{ lg: 3 }}
                        order={{ lg: 4 }}
                        className="text-center">
                        {logo && (
                            <ImageComponent
                                image={logo}
                                className={style.logo}></ImageComponent>
                        )}
                        <Text
                            className="normal text-primary-900 mt-2 mb-1"
                            tag="p">
                            {name}
                        </Text>
                        <Text className="small text-primary-600" tag="p">
                            {role}
                        </Text>
                    </Col>
                    <Col
                        col={{ xs: 12, lg: 1 }}
                        order={{ lg: 3 }}
                        mt={{ xs: 3, lg: 0 }}>
                        <div className={style.bottomQuotes}>
                            <BottomQuotes width="50"></BottomQuotes>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}

QuoteSection.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
};
