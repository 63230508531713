import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import OtherResourceCard from '../../OtherResourcesSection/components/OtherResourceCard';

export default function GatsbyOtherResourceCard(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "service-button" }
            ) {
                publicURL
                extension
            }
        }
    `);

    return <OtherResourceCard {...props} background={{ file: data.bg }} />;
}
