import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CtaSection from '../../CtaSection';

export default function GatsbyCtaSection(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "prefooter-background-high" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return <CtaSection {...props} background={{ file: data.bg }} />;
}
