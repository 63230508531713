import React from 'react';

import { Section, Container } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

export default function IframeSection({ src }) {
    return (
        <Section data-light="true">
            <Container>
                <div className={style.wrapperIframe}>
                    <iframe
                        src={src}
                        width="100%"
                        height="600px"
                        className={style.iframe}
                    />
                </div>
            </Container>
        </Section>
    );
}
