import React from 'react';
import PropTypes from 'prop-types';
import {
    Section,
    Container,
    Row,
    Col,
} from '@solublestudio/react-bootstrap-components';
import ServiceButtonComponent from 'ServiceButtonComponent';

function ServiceButtonSection({ serviceButtons }) {
    return (
        <Section data-light>
            <Container>
                <Row>
                    {serviceButtons.map((sb, i) => {
                        return (
                            <Col key={i} col={{ xs: 12, lg: 6 }} mb={{ xs: 3 }}>
                                <ServiceButtonComponent
                                    preTitle={sb.preTitle}
                                    title={sb.title}
                                    cta={sb.cta}
                                    background={sb.background}
                                    delay={i * 200}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Section>
    );
}

ServiceButtonSection.propTypes = {};

export default ServiceButtonSection;
