import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeroSection from '../../HeroSection';

export default function GatsbyHeroSection(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "bdeo-headergradient-nonoise" }
            ) {
                publicURL
                extension
            }
        }
    `);

    return <HeroSection {...props} video={data.bg} />;
}
