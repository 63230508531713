import React from 'react';

import style from './style.module.scss';

export default function HTMLTextComponent({ text, children, htmlClassName }) {
    return (
        <div
            className={[
                style.HTMLTextComponent,
                htmlClassName ? htmlClassName : '',
            ].join(' ')}
            {...(text && { dangerouslySetInnerHTML: { __html: text } })}>
            {!text && children ? children : null}
        </div>
    );
}
