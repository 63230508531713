import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TextHeroSection from '../../TextHeroSection';

export default function GatsbyTextHeroSection(props) {
    const data = useStaticQuery(graphql`
        {
            bg: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "texthero-background" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <TextHeroSection
            {...props}
            background={
                props.background?.file ? props.background : { file: data.bg }
            }
        />
    );
}
