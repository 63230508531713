import React, { useCallback } from 'react';

import {
    Col,
    Row,
    Heading,
    Text,
    Card,
} from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../../../ImageComponent';

import style from './style.module.scss';

function StepCard({ index, icon, title, description }) {
    const getTwoDigitsByNumber = useCallback((value) => {
        if (!value) {
            return;
        }

        const text = value.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });

        return text;
    }, []);

    return (
        <div data-point-active="false" className={style.point}>
            <Card
                wrapperClassName={[style.wrapper].join(' ')}
                bodyClassName={style.body}>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 2 }}
                        offset={{ lg: 1 }}
                        className="d-flex align-items-center">
                        <Text
                            tag="div"
                            className={`heading1 text-primary-100 ${style.index}`}>
                            {getTwoDigitsByNumber(index)}
                        </Text>
                    </Col>
                    <Col col={{ xs: 12, lg: 9 }}>
                        {icon && (
                            <ImageComponent
                                image={icon}
                                className={['mt-3', 'mt-lg-0', style.icon].join(
                                    ' ',
                                )}
                            />
                        )}
                        <Heading
                            tag="h3"
                            className="display3 mt-3 text-primary-100">
                            {title}
                        </Heading>
                        <Text tag="p" className="normal mt-3 text-primary-100">
                            {description}
                        </Text>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default StepCard;
