import React from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

function CtaSection({
    title,
    titleTag,
    description,
    cta,
    background,
    ...props
}) {
    return (
        <section
            {...(cta && background ? {} : { 'data-light': true })}
            className={`${style.section} ${
                props.previousSectionType === 'DatoCmsCtaSectionAlt' ||
                props.previousSectionType === 'DatoCmsTextImageSection'
                    ? style.marginSection
                    : ''
            }`}>
            {cta && background && (
                <ImageComponent className={style.image} image={background} />
            )}
            <Container pt={{ xs: 8, xxl: 12 }} pb={{ xs: 8, xxl: 12 }}>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 6 }}
                        offset={{ lg: 3 }}
                        className="text-center">
                        <Heading
                            className={`heading1 ${
                                cta && background
                                    ? 'text-primary-100'
                                    : 'text-primary-900'
                            }`}
                            tag={titleTag}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-0`}>
                            {title}
                        </Heading>
                        <Text
                            className={`normal ${
                                cta && background
                                    ? 'text-primary-100'
                                    : 'text-primary-600'
                            } ${cta ? 'mt-5' : 'mt-3 mt-xxl-5'}`}
                            tag="div"
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-200`}>
                            {description}
                        </Text>
                        {cta && (
                            <div
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-400`}>
                                <ButtonComponent
                                    button={{
                                        ...cta,
                                        type: 'primaryButton',
                                        className: 'mt-5 mt-xxl-8',
                                    }}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

CtaSection.defaultProps = {
    titleTag: 'h3',
};

CtaSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    cta: PropTypes.object,
    dark: PropTypes.bool,
    background: PropTypes.any,
};

export default CtaSection;
