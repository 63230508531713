import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CtaBannerCard from '../../CtaBannersSection/components/CtaBannerCard';

export default function GatsbyCtaBannerCard({ kind = 'primary', ...props }) {
    const data = useStaticQuery(graphql`
        {
            primary: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "bg-cta-banner" }
            ) {
                publicURL
                extension
            }
            secondary: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "service-button" }
            ) {
                publicURL
                extension
            }
        }
    `);

    return <CtaBannerCard {...props} background={{ file: data[kind] }} />;
}
