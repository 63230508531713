import React from 'react';
import {
    Col,
    Container,
    Row,
    Section,
} from '@solublestudio/react-bootstrap-components';

import TextImageSection from '../TextImageSection';
import TitleGradient from '../TitleGradient';

import ButtonComponent from '../ButtonComponent';

import styles from './styles.module.scss';

const StepsSection = ({ titleGradient, items = [], cta }) => {
    return (
        <Section data-light className="bg-primary-200">
            <Container>
                <Row>
                    <Col>
                        <TitleGradient
                            {...titleGradient}
                            className="text-center"
                        />
                    </Col>
                </Row>
            </Container>
            {items?.length > 0 &&
                items?.map((item, index) => (
                    <TextImageSection
                        classes={{
                            root: styles.textImageItemRoot,
                            wrapper: styles.textImageItemWrapper,
                        }}
                        key={`step-section-item-${index}`}
                        tag="div"
                        delay={index * 200}
                        {...item}
                    />
                ))}
            {cta && (
                <Container className="pt-5">
                    <Row>
                        <Col
                            col={{ xs: 12, lg: 10, xxl: 8 }}
                            offset={{ lg: 1, xxl: 2 }}
                            className="text-center">
                            <ButtonComponent
                                button={{
                                    ...cta,
                                    type: `primaryDarkButton`,
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </Section>
    );
};

export default StepsSection;
