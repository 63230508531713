import React, { useRef, useEffect, useState } from 'react';

import styles from './style.module.scss';

const EmbedVideo = ({ providerUid, title, className, classes }) => {
    const container = useRef();
    const [isVisible, setIsVisible] = useState(false);

    let videoObserver = null;

    if (typeof window !== 'undefined') {
        videoObserver = new IntersectionObserver(onVideoIntersection, {
            rootMargin: '250px 0px',
            threshold: 0.25,
        });
    }

    useEffect(() => {
        if (window && 'IntersectionObserver' in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else {
            setIsVisible(true);
        }
    }, [container]);

    function onVideoIntersection(entries) {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            setIsVisible(true);
            videoObserver.disconnect();
        }
    }

    return (
        <div
            ref={container}
            className={[styles.wrapper, classes?.wrapper].filter(Boolean).join(' ')}>
            <iframe
                src={
                    isVisible
                        ? `https://www.youtube.com/embed/${providerUid}`
                        : null
                }
                loading="lazy"
                frameBorder="0"
                className={[styles.video, className].filter(Boolean).join(' ')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={title}
            />
        </div>
    );
};

export default EmbedVideo;
