import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from '@solublestudio/react-bootstrap-components';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

export default function DescriptionComponent({
    title,
    titleTag,
    description,
    ctas = [],
    buttonClassName,
    delay = null,
}) {
    const hasSal = typeof delay === 'number';

    return (
        <>
            <Heading
                className="heading1 text-primary-900"
                tag={titleTag}
                {...(hasSal && {
                    'data-sal': 'slide-up',
                    'data-sal-delay': `xs-none--lg-${delay}`,
                })}>
                {title}
            </Heading>
            <Text
                className="normal text-primary-600 mb-4 mt-3 mb-lg-3 mt-xxl-5 mb-xxl-5"
                tag="div"
                {...(hasSal && {
                    'data-sal': 'slide-up',
                    'data-sal-delay': `xs-none--lg-${delay + 200}`,
                })}>
                {description}
            </Text>
            <div
                className={style.buttons}
                {...(hasSal && {
                    'data-sal': 'slide-up',
                    'data-sal-delay': `xs-none--lg-${delay + 400}`,
                })}>
                {ctas.map((cta, index) => {
                    return (
                        <ButtonComponent
                            key={index}
                            button={{
                                ...cta,
                                type: 'secondaryButton',
                                className: `${
                                    buttonClassName ? buttonClassName : ''
                                } ${style.button}`,
                            }}
                        />
                    );
                })}
            </div>
        </>
    );
}

DescriptionComponent.defaultProps = {};

DescriptionComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    ctas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            slug: PropTypes.string,
        }),
    ),
    buttonClassName: PropTypes.string,
};
