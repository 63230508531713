import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Section,
    Col,
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import HTMLComponent from './components/HtmlComponent';
import SocialLinks from './components/SocialLinks';

import CardListSection from '../CardListSection';
import ImageComponent from '../ImageComponent';

import ButtonComponent from '../ButtonComponent';

import Link from 'Link';

import style from './style.module.scss';

function BlogDetail({ post, backButton, relatedPostsSection, linksSection }) {
    const postDetail = useMemo(
        () => ({
            ...post,
            pretitle:
                post?.tags?.length > 0 && post?.tags[0]?.title?.toUpperCase(),
        }),
        [post],
    );

    const relatedPostsFormatted = useMemo(() => {
        return {
            ...relatedPostsSection,
            posts:
                relatedPostsSection?.posts?.length > 0
                    ? relatedPostsSection?.posts.map((post) => {
                          const { tags, author, meta } = post;

                          return {
                              ...post,
                              pretitle:
                                  tags?.length > 0 &&
                                  tags[0]?.title?.toUpperCase(),
                              horizontal: {
                                  xs: true,
                                  lg: true,
                              },
                          };
                      })
                    : [],
        };
    }, [relatedPostsSection]);

    return (
        <>
            <Section data-light="true" className={style.sectionInfo}>
                <Container>
                    <Row>
                        <Col col={{ xs: 12, lg: 6 }}>
                            <ButtonComponent
                                button={{
                                    ...backButton,
                                    type: 'tertiaryButton',
                                    className: 'mb-3',
                                }}
                            />
                            {postDetail?.pretitle && (
                                <Heading
                                    tag="h4"
                                    className="smallMono text-primary-600 mb-3">
                                    {postDetail?.pretitle}
                                </Heading>
                            )}

                            {postDetail?.title && (
                                <Heading
                                    tag="h1"
                                    className="display3 text-primary-900 mb-3">
                                    {postDetail?.title}
                                </Heading>
                            )}

                            {postDetail?.bottomText && (
                                <Text
                                    tag="div"
                                    className="smallMono text-uppercase text-primary-600 mt-3 mb-5 mb-lg-0">
                                    {postDetail?.bottomText}
                                </Text>
                            )}
                        </Col>
                        <Col col={{ xs: 12, lg: 6 }}>
                            {postDetail?.featuredImage && (
                                <ImageComponent
                                    image={post?.featuredImage}
                                    className={style.imageRounded}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </Section>

            <Section data-light="true" className={style.sectionDetail}>
                <Container>
                    <Row>
                        <Col col={{ xs: 12, lg: 10 }} offset={{ lg: 1 }}>
                            <HTMLComponent content={postDetail?.content} />
                            <div {...className('mt-5')}>
                                {post?.tags?.length > 0 &&
                                    post.tags.map((tag, i) => (
                                        <Link
                                            key={`footer-tag-${tag?.title}`}
                                            href={tag.slug}>
                                            <Text
                                                tag="span"
                                                className={[
                                                    'smallMono',
                                                    'text-primary-600',
                                                    style.postTagItem,
                                                ].join(' ')}>
                                                {tag?.title}
                                            </Text>
                                        </Link>
                                    ))}
                            </div>
                        </Col>
                        {linksSection && (
                            <Col col={{ lg: 1 }} className="d-none d-lg-block">
                                <SocialLinks
                                    className={style.verticalLinks}
                                    vertical
                                    links={linksSection?.links}
                                />
                            </Col>
                        )}

                        {linksSection && (
                            <Col col={{ xs: 12 }} className="d-lg-none mt-5">
                                {linksSection?.title && (
                                    <Text
                                        tag="p"
                                        className="text-uppercase text-primary-900 extraSmall mb-2">
                                        {linksSection?.title}
                                    </Text>
                                )}
                                <SocialLinks
                                    className={style.verticalLinks}
                                    padding={2}
                                    links={linksSection?.links}
                                />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Section>

            {relatedPostsFormatted && (
                <CardListSection
                    data-light="true"
                    cards={relatedPostsFormatted?.posts}
                    title={relatedPostsFormatted?.title}
                    titleTag="h3"
                    dark
                    xsCardColumns={12}
                    lgCardColumns={6}
                    xxlCardColumns={6}
                />
            )}
        </>
    );
}

BlogDetail.propTypes = {
    post: PropTypes.shape({}),
    backButton: PropTypes.shape(ButtonComponent.propTypes),
};

export default BlogDetail;
