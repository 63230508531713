import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from '@solublestudio/react-bootstrap-components';

import CtaBannerCard from 'CtaBannerCard';

function CtaBannersSection({ ctaBanners }) {
    return (
        <section data-light="true">
            <Container pt={{ xs: 8, xxl: 12 }} pb={{ xs: 8, xxl: 12 }}>
                <Row>
                    {ctaBanners.map((props, i) => {
                        return (
                            <Col
                                col={{ xs: 12, lg: 6 }}
                                className={
                                    i !== ctaBanners?.length - 1 &&
                                    'mb-5 mb-lg-0'
                                }
                                key={`Cta-Banner-${i}`}>
                                <CtaBannerCard {...props} />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
}

CtaBannersSection.defaultProps = {
    ctaBanners: [],
};

CtaBannersSection.propTypes = {
    ctaBanners: PropTypes.arrayOf(PropTypes.shape(CtaBannerCard.propTypes)),
};

export default CtaBannersSection;
