import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Heading,
    Row,
    Col,
    Text,
    className,
    Section,
    useIsMobile,
} from '@solublestudio/react-bootstrap-components';

import Tag from '../Tag';
import Select from '../Select';
import { InputEraser } from '../Input';

import ImageComponent from '../ImageComponent';
import CardListPaginationSection from '../CardListPaginationSection';

import { ReactComponent as Loupe } from '../../img/Loupe.svg';

import Link, { navigate } from 'Link';

import style from './style.module.scss';

function BlogSection({
    background,
    currentSlug,
    dark = false,
    defaultTagText,
    description,
    emptyText,
    firstPageHref,
    hintText,
    isLoading,
    numPages = 0,
    pageHref,
    pageNr = 1,
    posts,
    searchURL = 'search',
    tags,
    term,
    title,
    titleTag,
}) {
    const isMobile = useIsMobile();
    const [showForm, setShowForm] = useState(!!term);

    useEffect(() => {
        setShowForm(isMobile);
    }, [isMobile]);

    useEffect(() => {
        setShowForm(!!term);
    }, [term]);

    const styleTextDark = useMemo(
        () => (dark ? 'text-primary-900' : 'text-primary-100'),
        [dark],
    );

    const options = useMemo(
        () =>
            tags.reduce(
                (object, item) => ((object[item.slug] = item.title), object),
                {},
            ),
        [tags],
    );

    const formattedPosts = useMemo(() => {
        return posts?.length > 0
            ? posts.map((post, i) => {
                  return {
                      ...post,
                      pretitle:
                          post?.tags?.length > 0 &&
                          post?.tags[0]?.title?.toUpperCase(),
                      cardImageStyle: post?.cardImageStyle ?? 'cover',
                      horizontal: { xs: false, lg: true },
                      detached: false,
                      kind: 'blog',
                  };
              })
            : [];
    }, [posts]);

    const onChangeTag = useCallback(
        (ev) => {
            let value = ev?.target.value;

            value = value ? value : firstPageHref;

            navigate
                ? navigate(`/${value}`)
                : typeof window !== undefined
                ? (window.location.href = value)
                : null;
        },
        [navigate, firstPageHref],
    );

    return (
        <>
            <Section
                {...(dark && { 'data-light': 'true' })}
                {...className(
                    [style.section, dark && style.dark]
                        .filter((el) => !!el)
                        .join(' '),
                )}>
                {background && !dark && (
                    <ImageComponent
                        className={style.image}
                        image={background}
                    />
                )}
                <Container>
                    <Row>
                        <Col
                            col={{ xs: 12, lg: 10 }}
                            offset={{ lg: 1 }}
                            className="text-center">
                            <Heading
                                tag={titleTag}
                                className={['display1', styleTextDark].join(
                                    ' ',
                                )}>
                                {title}
                            </Heading>
                            {description ? (
                                <Text
                                    className={[
                                        'normal',
                                        'mt-3',
                                        styleTextDark,
                                    ].join(' ')}
                                    tag="p">
                                    {description}
                                </Text>
                            ) : (
                                <div {...className('mt-6')} />
                            )}
                            <div {...className('mt-3 d-none d-lg-flex')}>
                                <ul
                                    {...className(
                                        `list-inline ${style.listTags}`,
                                    )}>
                                    <li
                                        key={`tag-all`}
                                        {...className(
                                            `list-inline-item ${style.tagItem}`,
                                        )}>
                                        <Tag
                                            label={defaultTagText || 'all'}
                                            slug={firstPageHref}
                                            active={
                                                firstPageHref === currentSlug
                                            }
                                            dark={dark}
                                        />
                                    </li>
                                    {tags?.length > 0 &&
                                        tags.map(({ title, slug }) => {
                                            return (
                                                <li
                                                    key={`tag-${slug}`}
                                                    {...className(
                                                        `list-inline-item ${style.tagItem}`,
                                                    )}>
                                                    <Tag
                                                        label={title}
                                                        slug={slug}
                                                        active={
                                                            slug === currentSlug
                                                        }
                                                        dark={dark}
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                                <div
                                    {...className(
                                        `list-inline-item ${style.searchItem}`,
                                    )}>
                                    <button
                                        className={style.loupeBtn}
                                        onClick={(ev) => {
                                            ev?.preventDefault();
                                            setShowForm((show) => !show);
                                        }}>
                                        <Loupe
                                            className={[
                                                style.icon,
                                                dark && style.dark,
                                            ].join(' ')}
                                        />
                                    </button>
                                </div>
                            </div>

                            <div
                                {...className(
                                    [
                                        'mt-2',
                                        style.wrapperForm,
                                        showForm && style['wrapperForm--show'],
                                    ]
                                        .filter((el) => !!el)
                                        .join(' '),
                                )}>
                                <form
                                    action={`/${searchURL}/`}
                                    method="get"
                                    className={[style.form].join(' ')}>
                                    <div {...className('text-left')}>
                                        <InputEraser
                                            dark={dark}
                                            outline
                                            defaultValue={term}
                                            name="term"
                                            placeholder="Buscar"
                                        />
                                        {hintText && (
                                            <Text
                                                className={[
                                                    'extraSmall',
                                                    style.hintText,
                                                    styleTextDark,
                                                ].join(' ')}
                                                tag="p">
                                                {hintText}
                                            </Text>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div {...className('d-block d-lg-none mt-1')}>
                                <Select
                                    placeholder={defaultTagText}
                                    dark={dark}
                                    outline
                                    value={currentSlug}
                                    onChange={onChangeTag}
                                    options={options}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <CardListPaginationSection
                isLoading={isLoading}
                emptyText={emptyText}
                cards={formattedPosts}
                highlighted={pageNr === 1}
                xsCardColumns={12}
                lgCardColumns={6}
                xxlCardColumns={6}
                sectionClassName={style.sectionList}
                paginationParams={{
                    pages: numPages,
                    firstPageHref: firstPageHref
                        ? `/${firstPageHref}/`
                        : '/blog',
                    pageHref: pageHref
                        ? `${pageHref}/{page}`
                        : 'blog/page/{page}',
                    activePage: pageNr || 1,
                    LinkTag: Link,
                }}
            />
        </>
    );
}

BlogSection.propTypes = {
    dark: PropTypes.bool,
};

export default BlogSection;
