import React from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
    useIsMobile,
    className,
} from '@solublestudio/react-bootstrap-components';
import ImageComponent from '../ImageComponent';
import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';
import CardVideo from '../CardVideo';
function HeroSectionAlt({
    preTitle,
    preTitleCta,
    title,
    titleTag,
    description,
    cta,
    backgroundImage,
    mobileBackground,
    backgroundColor,
    careersCta,
    careersImage,
    video,
    videoImage,
}) {
    const isMobile = useIsMobile();

    return (
        <Section
            {...(backgroundColor ? {} : { 'data-light': true })}
            className={`${style.section} ${
                backgroundColor
                    ? `${style.backgroundGradient} ${style[backgroundColor]}`
                    : `${style.backgroundNormal}`
            }`}>
            <Container className={style.container}>
                <Row>
                    <Col
                        col={{
                            xs: 12,
                            lg: 7,
                            xxl: backgroundColor ? 9 : 6,
                        }}
                        offset={{ xxl: backgroundColor ? 1 : 0 }}>
                        {preTitleCta ? (
                            <ButtonComponent
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-0`}
                                button={{
                                    ...preTitleCta,
                                    color: preTitleCta.hover,
                                    type: 'tertiaryButton',
                                }}
                            />
                        ) : (
                            <Heading
                                tag="h4"
                                className="smallMono text-primary-100"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-0`}>
                                {preTitle}
                            </Heading>
                        )}
                        <Heading
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-200`}
                            tag={titleTag}
                            className={`display1 mt-3 ${style.title} ${
                                backgroundColor
                                    ? 'text-primary-100'
                                    : 'text-primary-900'
                            }`}>
                            {title}
                        </Heading>
                        {description && (
                            <Text
                                tag="div"
                                className="normal text-primary-600 mt-3 mt-xxl-5"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-400`}>
                                {description}
                            </Text>
                        )}
                        {careersImage && (
                            <a
                                href={careersCta.externalLink}
                                target="_blank"
                                {...className(
                                    `d-inline-block mt-3 ${style.careersImage}`,
                                )}>
                                <ImageComponent
                                    image={careersImage}></ImageComponent>
                            </a>
                        )}
                        <div
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-600`}>
                            <ButtonComponent
                                button={{
                                    ...cta,
                                    type: backgroundColor
                                        ? 'primaryButton'
                                        : 'primaryDarkButton',
                                    className: `mt-5 ${
                                        description ? '' : 'mt-xxl-8'
                                    }`,
                                }}
                            />
                        </div>
                    </Col>
                    {video && !backgroundColor ? (
                        <Col
                            className="d-flex align-items-center"
                            col={{ lg: 6 }}
                            mt={{ xs: 5, lg: 0 }}>
                            <CardVideo
                                cardClassName={style.videoCard}
                                video={video}
                                background={videoImage}></CardVideo>
                        </Col>
                    ) : null}
                </Row>
            </Container>
            {!video && (mobileBackground || backgroundImage) ? (
                <ImageComponent
                    className={style.image}
                    image={
                        isMobile && mobileBackground
                            ? mobileBackground
                            : backgroundImage
                    }
                />
            ) : null}
        </Section>
    );
}

HeroSectionAlt.propTypes = {
    preTitle: PropTypes.string,
    preTitleCta: PropTypes.shape({
        label: PropTypes.string,
        slug: PropTypes.string,
    }),
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    cta: PropTypes.shape({
        label: PropTypes.string,
        slug: PropTypes.string,
    }),
    backgroundImage: PropTypes.object,
    backgroundColor: PropTypes.oneOf(['red', 'green', 'blue', 'purple']),
};

export default HeroSectionAlt;
