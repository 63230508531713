import React from 'react';
import PropTypes from 'prop-types';

import {
    className,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../../../ButtonComponent';
import HubspotForm from '../../../HubspotForm';
import CardBG from '../../../CardBG';

import style from './style.module.scss';

function CtaBannerCard({
    background,
    portalId,
    formId,
    cta,
    titleTag,
    description,
    title,
}) {
    return (
        <CardBG background={background}>
            <Heading
                className={`display3 ${
                    background ? 'text-primary-100' : 'text-primary-900'
                } ${style.title}`}
                tag={titleTag || 'h4'}>
                {title}
            </Heading>

            <Text
                className={`normal ${
                    background ? 'text-primary-100' : 'text-primary-600'
                } mt-2 mt-lg-5`}
                tag="div">
                {description}
            </Text>

            {cta && (
                <div>
                    <ButtonComponent
                        button={{
                            ...cta,
                            type: 'primaryButton',
                            className: 'mt-5 mt-lg-9',
                        }}
                    />
                </div>
            )}

            {portalId && formId && (
                <div {...className(`mt-5 mt-lg-9 ${style.formBottom}`)}>
                    <HubspotForm
                        subscriptionForm
                        portalId={portalId}
                        formId={formId}
                        submitDisabled={false}
                    />
                </div>
            )}
        </CardBG>
    );
}

CtaBannerCard.defaultProps = {
    titleTag: 'h4',
};

CtaBannerCard.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    cta: PropTypes.object,
    dark: PropTypes.bool,
    background: PropTypes.any,
    portalId: PropTypes.string,
    formId: PropTypes.string,
};

export default CtaBannerCard;
