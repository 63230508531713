import React from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    className,
} from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../ImageComponent';
import DescriptionComponent from '../DescriptionComponent';

import style from './style.module.scss';

function TextImageSection({
    title,
    titleTag,
    description,
    cta,
    image,
    imageRight,
    tag: Tag = 'section',
    withBackground = true,
    classes = {},
    delay = null,
}) {
    return (
        <Tag
            data-light
            className={[style.section, classes?.root]
                ?.filter(Boolean)
                .join(' ')}>
            <Container>
                <Row
                    className={[
                        `pt-7 pb-8 pt-lg-15 pb-lg-15`,
                        withBackground && style.background,
                        classes?.wrapper,
                    ]
                        .filter(Boolean)
                        .join(' ')}
                    data-sal="slide-up"
                    data-sal-delay={`xs-none--lg-0`}>
                    <Col
                        col={{ xs: 12, lg: 6, xxl: 4 }}
                        offset={{ xxl: 1 }}
                        order={{ lg: imageRight ? 2 : 1 }}>
                        <div
                            {...className(
                                `ml-3 mr-3 ml-lg-6 mr-lg-6 ml-xxl-0 mr-xxl-0`,
                            )}>
                            <ImageComponent
                                image={image}
                                className={style.image}
                            />
                        </div>
                    </Col>
                    <Col
                        col={{ xs: 12, lg: 5, xxl: 4 }}
                        offset={{
                            lg: imageRight ? 1 : 0,
                            xxl: imageRight ? 2 : 1,
                        }}
                        order={{ lg: imageRight ? 1 : 2 }}
                        mt={{ xs: 7, lg: 0 }}
                        className="d-flex align-items-center">
                        <div {...className('pl-3 pr-3 pl-lg-0 pr-lg-0')}>
                            <DescriptionComponent
                                title={title}
                                titleTag={titleTag}
                                description={description}
                                ctas={cta ? [cta] : []}
                                delay={delay}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Tag>
    );
}

TextImageSection.defaultProps = {
    titleTag: 'h4',
};

TextImageSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    description: PropTypes.string,
    cta: PropTypes.object,
    image: PropTypes.any,
    imageRight: PropTypes.bool,
    withBackground: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
        wrapper: PropTypes.string,
    }),
    delay: PropTypes.number,
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default TextImageSection;
