import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Section,
    Container,
    Row,
    Col,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import FeaturesIconsSection from '../FeaturesIconsSection';
import FeatureIconComponent from '../FeatureIconComponent';

import style from './style.module.scss';
import ButtonComponent from '../ButtonComponent';
function KnowUsSection({
    title,
    titleTag,
    subtitle,
    features,
    cta,
    previousSectionType,
}) {
    const hasFeautures = useMemo(() => features?.length > 0, [features]);
    return (
        <>
            <Section
                className={[
                    style.knowUsSection,
                    previousSectionType === 'DatoCmsInternalHero'
                        ? style.withShadow
                        : '',
                    'bg-primary-1000',
                    !hasFeautures ? style.withOutFeatures : '',
                ]
                    .filter(Boolean)
                    .join(' ')}>
                <Container>
                    <Row>
                        <Col col={{ xs: 12, lg: 5 }} offset={{ xxl: 1 }}>
                            <Heading
                                tag={titleTag}
                                className="display3 text-primary-100 mb-5 mb-lg-0"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-0`}>
                                {title}
                            </Heading>
                        </Col>
                        <Col col={{ xs: 12, lg: 6, xxl: 5 }}>
                            <Text
                                tag="div"
                                className="normal text-primary-400"
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-200`}>
                                {subtitle}
                            </Text>
                            {cta && (
                                <ButtonComponent
                                    button={{
                                        ...cta,
                                        type: 'primaryButton',
                                        className: 'mt-3',
                                    }}></ButtonComponent>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Section>
            {hasFeautures && (
                <FeaturesIconsSection
                    features={features}
                    className={style.featuresSection}
                />
            )}
        </>
    );
}

KnowUsSection.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
    subtitle: PropTypes.string,
    features: PropTypes.arrayOf(
        PropTypes.shape(FeatureIconComponent.propTypes),
    ),
};

export default KnowUsSection;
