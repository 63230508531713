import React from 'react';
import PropTypes from 'prop-types';

import {
    Row,
    Container,
    Col,
    className,
} from '@solublestudio/react-bootstrap-components';

import ResourceCard from '../ResourceCard';
import TitleGradient from '../TitleGradient';

export default function HighlightedResourceSection({ pretitle, resource }) {
    return (
        <section
            {...className(
                ['bg-primary-900', 'pt-8', 'pb-8', 'pt-lg-10', 'pb-lg-10'].join(
                    ' ',
                ),
            )}>
            <Container>
                <Row>
                    <Col col={{ xs: 12 }}>
                        <ResourceCard
                            titleTag="h4"
                            horizontal={{ xs: false, lg: true }}
                            {...resource}
                            pretitle={
                                <TitleGradient
                                    colorPattern="blue"
                                    className="extraSmall text-uppercase"
                                    title={`<b>${pretitle}</b>`}
                                    titleTag="h6"
                                    dark
                                />
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

HighlightedResourceSection.propTypes = {
    pretitle: PropTypes.string,
    resource: PropTypes.shape(ResourceCard.propTypes),
};
